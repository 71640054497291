import { useContext } from 'react';
import PropTypes from 'prop-types';
import { MeContext } from '../context/MeContext';
import { Navigate } from 'react-router';

const FeatureFlagGuard = ({ children, flagKey }) => {
  const meCtx = useContext(MeContext);
  const { state } = meCtx;

  if (state[flagKey]) {
    return children;
  }

  return <Navigate to="/dashboard" />;
};

FeatureFlagGuard.propTypes = {
  children: PropTypes.node.isRequired,
  flagKey: PropTypes.string.isRequired,
};

export default FeatureFlagGuard;
