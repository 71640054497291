import { Checkbox, Box, useColorModeValue } from '@chakra-ui/react';

function CustomCheckbox({ label, description = '', isChecked, onChange }) {
  const lineHeight = description ? '1' : 'normal';
  return (
    <Checkbox
      gap="4"
      alignItems="flex-start"
      isChecked={isChecked}
      onChange={onChange}
    >
      <Box lineHeight={lineHeight} fontWeight={description ? 'bold' : 'normal'}>
        {label}
      </Box>
      {description && (
        <Box
          lineHeight="normal"
          color={useColorModeValue('secondaryDark08', 'white')}
          mt="1"
        >
          {description}
        </Box>
      )}
    </Checkbox>
  );
}

export default CustomCheckbox;
