import {
  Container,
  useColorModeValue,
  useDisclosure,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  HStack,
  Text,
} from '@chakra-ui/react';
import Breadcrumbs from '../../common/Breadcrumbs';
import MessagesHeader from '../../components/client/messages/MessagesHeader';
import MessagesCreatePage from './MessagesCreatePage';
import MessagingTermsModal from '../../components/modals/MessagingTermsModal';
import { useEffect, useContext, useState } from 'react';
import useOrganizationStatus from '../../hooks/useOrganisationStatus';
import EmptyArtistAnimated from '../../theme/illustrations-animated/EmptyArtistAnimated';
import EmptyArtistDarkAnimated from '../../theme/illustrations-animated/EmptyArtistDarkAnimated';
import { MessageContextProvider } from '../../store/client/MessageContext';
import { MeContext } from '../../context/MeContext';
import { CustomEmailMultiIcon } from '../../theme/multicolor-icons/CustomEmailMultiIcon';
import { CustomSmsMultiIcon } from '../../theme/multicolor-icons/CustomSmsMultiIcon';
import SmsMessagesPage from './SmsMessagesPage';

const MessagesPage = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const meCtx = useContext(MeContext);
  const { filterEntity, entities } = meCtx.state;
  const [mobileMessagingEnabled, setMobileMessagingEnabled] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);

  const selectedEntity = entities?.length > 1 ? filterEntity : entities?.[0];

  useEffect(() => {
    setMobileMessagingEnabled(
      selectedEntity?.mobile_messaging_enabled || false
    );
  }, [selectedEntity]);

  useEffect(() => {
    if (!mobileMessagingEnabled && tabIndex !== 0) {
      setTabIndex(0);
    }
  }, [mobileMessagingEnabled, tabIndex]);

  const modeSvgIcon = useColorModeValue(
    <EmptyArtistAnimated />,
    <EmptyArtistDarkAnimated />
  );

  const breadcrumbLinks = [
    {
      linkName: 'Home',
      linkUrl: '/',
    },
    {
      linkName: 'Messaging',
      linkUrl: '/messaging',
    },
    {
      linkName: 'Create message',
      linkUrl: '/messaging/create',
    },
  ];

  const { components, shouldRender } = useOrganizationStatus(modeSvgIcon);

  useEffect(() => {
    onOpen();
  }, [onOpen]);

  return (
    <Container display="flex" flexDir="column" flex="1" pb={6}>
      <Breadcrumbs breadcrumbLinks={breadcrumbLinks} />
      <MessagesHeader isCreatePage={true} />

      {components}

      {shouldRender && (
        <>
          <Tabs index={tabIndex} onChange={(index) => setTabIndex(index)}>
            <TabList>
              <Tab>
                <HStack alignItems="center">
                  <CustomSmsMultiIcon fontSize="18px" mb={0} />
                  <Text textStyle="bodyMedium">Wallet Notifications</Text>
                </HStack>
              </Tab>
              {mobileMessagingEnabled && (
                <Tab>
                  <HStack alignItems="center">
                    <CustomEmailMultiIcon fontSize="18px" mb={0} />
                    <Text textStyle="bodyMedium">SMS/MMS Messages</Text>
                  </HStack>
                </Tab>
              )}
            </TabList>

            <TabPanels mt={8}>
              <TabPanel px="0">
                <MessageContextProvider>
                  <MessagesCreatePage />
                  <MessagingTermsModal isOpen={isOpen} onClose={onClose} />
                </MessageContextProvider>
              </TabPanel>
              {mobileMessagingEnabled && (
                <TabPanel px="0">
                  <SmsMessagesPage filterEntity={selectedEntity} />
                </TabPanel>
              )}
            </TabPanels>
          </Tabs>
        </>
      )}
    </Container>
  );
};
export default MessagesPage;
