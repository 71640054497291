import { useEffect, useContext, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  Heading,
  Input,
  Center,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  VStack,
  SimpleGrid,
  GridItem,
  useBreakpointValue,
  Button,
  Box,
  Switch,
  Link,
  Text,
  InputGroup,
  InputRightElement,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import CardForm from '../../components/common/CardForm';
import {
  REGEX_CONTAINS_BOTH_LOWER_AND_UPPERCASE,
  REGEX_HAS_AT_LEAST_ONE_NUMBER,
  REGEX_HAS_SYMBOL,
  lengthInBetween,
  hasLowerAndUpperCase,
  hasSymbol,
  hasDigit,
} from '../../utils/validations';
import { CustomCheckIcon } from '../../theme/icons/CustomCheckIcon';
import { CustomExclamationMarkIcon } from '../../theme/icons/CustomExclamationMarkIcon';
import { CustomVisibilityIcon } from '../../theme/icons/CustomVisibilityIcon';
import { CustomVisibilityOffIcon } from '../../theme/icons/CustomVisibilityOffIcon';
import { Link as RouterLink } from 'react-router-dom';
import { FORM_PADDING_TOP } from '../../utils/consts';

import { HttpContext } from '../../context/HttpContext';
import CustomToast from '../../common/CustomToast';
import appsignal from '../../appsignal';

function RegisterTicketingEventsPage() {
  const { authAxios, publicAxios } = useContext(HttpContext);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const staticToken = queryParams.get('inviteToken');

  const { inviteToken } = useParams();

  const [inviteeEmail, setInviteeEmail] = useState('');
  const [inviteeCompany, setInviteeCompany] = useState('');

  const [show, setShow] = useState(false);
  const showPasswordClickHandler = () => setShow(!show);

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('Please enter your first name'),
    lastName: Yup.string().required('Please enter your last name'),
    companyName: inviteToken
      ? Yup.string()
      : Yup.string().required('Please enter your company name'),
    email: inviteToken
      ? Yup.string()
      : Yup.string()
          .required('Please enter your email address')
          .email('Please enter a valid email address'),
    plainPassword:
      (!!inviteToken || !!staticToken) &&
      Yup.string()
        .required('Please enter your password')
        .min(6, 'Please enter at least 6 characters')
        .max(30, 'Please enter 30 characters or less')
        .matches(REGEX_CONTAINS_BOTH_LOWER_AND_UPPERCASE)
        .matches(REGEX_HAS_AT_LEAST_ONE_NUMBER)
        .matches(REGEX_HAS_SYMBOL),
    passwordConfirmation:
      (!!inviteToken || !!staticToken) &&
      Yup.string()
        .required('Please confirm your password')
        .oneOf([Yup.ref('plainPassword'), null], 'Passwords must match'),
    termsAndCond: Yup.boolean().isTrue(),
  });
  const formOptions = {
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isDirty, isValid },
    watch,
    getFieldState,
    reset,
  } = useForm(formOptions);
  const colSpan = useBreakpointValue({ base: 2, sm: 1 });

  const watchPassword = watch('plainPassword');
  const fieldStatePassword = getFieldState('plainPassword');
  const toast = useToast();

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await authAxios.get(
          `/accounts/invitation/accept?invitation_token=${inviteToken}`
        );
        setInviteeEmail(response.data.account.email);
        setInviteeCompany(response.data.account.company_name);
        register('email', { value: response.data.account.email });
        register('companyName', { value: response.data.account.company_name });
      } catch (error) {
        console.log(error);
      }
    };
    if (inviteToken) {
      getData();
    }
  }, [inviteToken]);

  const injectHubSpotScript = () => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.id = 'hs-script-loader';
    script.async = true;
    script.defer = true;
    script.src = '//js-eu1.hs-scripts.com/139540062.js';
    document.head.appendChild(script);
  };

  useEffect(() => {
    if (
      !staticToken &&
      !inviteToken &&
      (process.env.REACT_APP_ENV || window.env?.REACT_APP_NODE_ENV) ===
        'production'
    ) {
      injectHubSpotScript();
    }
  }, [staticToken, inviteToken]);

  const onSubmit = async (values) => {
    try {
      if (inviteToken) {
        await authAxios.patch(`/accounts/invitation`, {
          account: {
            invitationToken: inviteToken,
            firstName: values.firstName,
            lastName: values.lastName,
            password: values.plainPassword,
            passwordConfirmation: values.passwordConfirmation,
          },
        });
        toast({
          render: (props) => (
            <CustomToast
              status="success"
              title="Registration completed"
              description="You can now login using your credentials"
              onClose={props.onClose}
            />
          ),
        });
        reset();
        navigate(`/login`, { replace: true });
      } else {
        if (staticToken) {
          await publicAxios.post(`/accounts`, {
            account: {
              email: values.email,
              password: values.plainPassword,
              first_name: values.firstName,
              last_name: values.lastName,
              company_name: values.companyName,
              phone_number: values.phoneNumber,
            },
            invite_token: staticToken,
          });
        }
        reset();
        const thankYouPath = staticToken
          ? `/thank-you?token=${staticToken}`
          : `/thank-you`;
        navigate(thankYouPath, { replace: true });
      }
    } catch (onError) {
      appsignal.sendError(onError);
      console.log(onError);
      const errors = onError.response.data.errors;
      if (
        errors &&
        errors[0] === 'Email was already confirmed, please try signing in'
      ) {
        toast({
          render: (props) => (
            <CustomToast
              status="success"
              title="Registration complete. You can now login."
              description=""
              onClose={props.onClose}
              id={props.id}
            />
          ),
        });
      } else {
        errors.forEach((error) => {
          toast({
            render: (props) => (
              <CustomToast
                status="error"
                title={error}
                description=""
                onClose={props.onClose}
              />
            ),
          });
        });
      }
    }
  };

  const modeIconColor = useColorModeValue('secondaryDark06', '#DDDDDD');

  return (
    <Center pt={FORM_PADDING_TOP} pb="10px">
      <CardForm>
        <form onSubmit={handleSubmit(onSubmit)}>
          <VStack spacing={5} alignItems="flex-start">
            <Heading>Enquire now</Heading>
            <SimpleGrid w="full" columns={2} columnGap={4} rowGap={[5, 0]}>
              <GridItem colSpan={colSpan}>
                <FormControl isRequired isInvalid={errors.firstName}>
                  <FormLabel>First name</FormLabel>
                  <Input
                    id="firstName"
                    type="text"
                    placeholder="Your name"
                    {...register('firstName')}
                  />
                  <FormErrorMessage mt={2}>
                    {errors.firstName?.message || errors.firstName}
                  </FormErrorMessage>
                </FormControl>
              </GridItem>
              <GridItem colSpan={colSpan}>
                <FormControl isRequired isInvalid={errors.lastName}>
                  <FormLabel>Last name</FormLabel>
                  <Input
                    id="lastName"
                    type="text"
                    placeholder="Your last name"
                    {...register('lastName')}
                  />
                  <FormErrorMessage mt={2}>
                    {errors.lastName?.message || errors.lastName}
                  </FormErrorMessage>
                </FormControl>
              </GridItem>
            </SimpleGrid>
            <FormControl isRequired isInvalid={errors.companyName}>
              <FormLabel>Company name</FormLabel>
              <Input
                id="companyName"
                type="text"
                placeholder={inviteeCompany || 'Enter company name'}
                isDisabled={inviteToken}
                {...register('companyName')}
              />
              <FormErrorMessage mt={2}>
                {errors.companyName?.message || errors.companyName}
              </FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={errors.email}>
              <FormLabel>Email</FormLabel>
              <Input
                id="email"
                type="email"
                placeholder={inviteeEmail || 'Your email address'}
                isDisabled={inviteToken}
                {...register('email')}
              />
              <FormErrorMessage mt={2}>
                {errors.email?.message || errors.email}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.phone}>
              <FormLabel>Phone number</FormLabel>
              <Input
                id="phone"
                type="text"
                placeholder="Your phone number"
                {...register('phone')}
              />
              <FormErrorMessage mt={2}>
                {errors.phone?.message || errors.phone}
              </FormErrorMessage>
            </FormControl>
            {(!!staticToken || !!inviteToken) && (
              <>
                <FormControl isRequired isInvalid={errors.plainPassword}>
                  <FormLabel>Password</FormLabel>
                  <InputGroup>
                    <Input
                      id="plainPassword"
                      type={show ? 'text' : 'password'}
                      // type="text"
                      placeholder="Enter password"
                      {...register('plainPassword')}
                    />
                    <InputRightElement>
                      {show ? (
                        <CustomVisibilityOffIcon
                          onClick={showPasswordClickHandler}
                          boxSize="18px"
                          cursor="pointer"
                          color={modeIconColor}
                        />
                      ) : (
                        <CustomVisibilityIcon
                          onClick={showPasswordClickHandler}
                          boxSize="18px"
                          cursor="pointer"
                          color={modeIconColor}
                        />
                      )}
                    </InputRightElement>
                  </InputGroup>
                  {fieldStatePassword.invalid && (
                    <FormHelperText mt={2}>
                      <VStack spacing={0} alignItems="left">
                        <Box color="error">Please enter your password:</Box>
                        <Box
                          color={
                            lengthInBetween(watchPassword, 6, 30)
                              ? 'success'
                              : 'error'
                          }
                        >
                          {lengthInBetween(watchPassword, 6, 30) ? (
                            <CustomCheckIcon boxSize={2} mr={1} />
                          ) : (
                            <CustomExclamationMarkIcon boxSize={2} mr={1} />
                          )}
                          contains at least 6 and maximum 30 characters
                        </Box>
                        <Box
                          color={
                            hasLowerAndUpperCase(watchPassword)
                              ? 'success'
                              : 'error'
                          }
                        >
                          {hasLowerAndUpperCase(watchPassword, 6, 30) ? (
                            <CustomCheckIcon boxSize={2} mr={1} />
                          ) : (
                            <CustomExclamationMarkIcon boxSize={2} mr={1} />
                          )}
                          contains both lower (a-z) and upper case letters (A-Z)
                        </Box>
                        <Box
                          color={
                            hasDigit(watchPassword) && hasSymbol(watchPassword)
                              ? 'success'
                              : 'error'
                          }
                        >
                          {hasDigit(watchPassword) &&
                          hasSymbol(watchPassword) ? (
                            <CustomCheckIcon boxSize={2} mr={1} />
                          ) : (
                            <CustomExclamationMarkIcon boxSize={2} mr={1} />
                          )}
                          contains at least one number (0-9) and a symbol
                        </Box>
                      </VStack>
                    </FormHelperText>
                  )}
                </FormControl>
                <FormControl isRequired isInvalid={errors.passwordConfirmation}>
                  <FormLabel>Password confirmation</FormLabel>
                  <Input
                    id="passwordConfirmation"
                    type={show ? 'text' : 'password'}
                    placeholder="Confirm your password"
                    {...register('passwordConfirmation')}
                  />
                  <FormErrorMessage mt={2}>
                    {errors.passwordConfirmation?.message ||
                      errors.passwordConfirmation}
                  </FormErrorMessage>
                </FormControl>
              </>
            )}
            <FormControl display="flex" alignItems="center">
              <Switch
                defaultChecked
                id="termsAndCond"
                mr="10px"
                {...register('termsAndCond')}
              />
              <FormLabel mb="0">
                <Text as="span" textStyle="bodyFamilyRegular">
                  I agree to
                </Text>{' '}
                <Link as={RouterLink} to="/" color="brand" fontSize="16px">
                  Terms &amp; Conditions
                </Link>
              </FormLabel>
            </FormControl>
            <Box w="full">
              <Button
                type="submit"
                isLoading={isSubmitting}
                disabled={!isDirty || !isValid || isSubmitting}
                mt={3}
                alt="Submit"
              >
                Submit
              </Button>
            </Box>
            <Text textStyle="bodyRoman">
              All fields marked with{' '}
              <Box as="span" color="error">
                *
              </Box>{' '}
              are mandatory
            </Text>
          </VStack>
        </form>
      </CardForm>
    </Center>
  );
}

export default RegisterTicketingEventsPage;
