import appsignal from '../../../appsignal';
import EventsListContext from '../../../store/client/EventsListContext';
import { useMemo, useContext, useRef, useCallback } from 'react';
import EventsListTable from './EventsListTable';
import moment from 'moment';
import CustomToast from '../../../common/CustomToast';
import { useToast } from '@chakra-ui/react';
import { getErrorResponsePayload } from '../../../utils/ajax';
import { HttpContext } from '../../../context/HttpContext';
import { MeContext } from '../../../context/MeContext';

const EventsList = () => {
  const {
    eventState,
    eventState: { items, total, pageCount, loading },
    updateEventState,
  } = useContext(EventsListContext);
  const { authAxios } = useContext(HttpContext);
  const toast = useToast();
  const fetchIdRef = useRef(0);
  const meCtx = useContext(MeContext);
  const { filterEntity } = meCtx.state;

  const columns = useMemo(
    () => [
      {
        Header: 'Event Name',
        accessor: 'name',
        className: 'modeTdColor',
        style: {
          fontFamily: 'Inter Bold, sans-serif',
          fontSize: '16px',
          wordBreak: 'break-word',
          minWidth: '150px',
          width: '250px',
        },
      },
      {
        Header: 'Venue Name',
        accessor: 'venueName',
        style: {
          minWidth: '100px',
          width: '200px',
          wordBreak: 'break-word',
        },
      },
      {
        Header: 'Start date',
        accessor: 'eventStartDate',
        style: {
          width: '100px',
        },
      },
      {
        Header: 'Start time',
        accessor: 'eventStartTime',
        style: {
          width: '100px',
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
        style: {
          width: '100px',
        },
      },
      {
        Header: '',
        accessor: 'actionFirst',
        style: {
          width: '50px',
          paddingRight: '20px',
        },
      },
      {
        Header: '',
        accessor: 'actionSecond',
        style: {
          width: '50px',
        },
      },
      {
        Header: ' ',
        accessor: 'moreInfo',
        style: {
          width: '24px',
          padding: 0,
        },
      },
    ],
    []
  );

  const fetchData = useCallback(
    ({ pageSize, pageIndex }) => {
      // Give this fetch an ID
      const fetchId = ++fetchIdRef.current;

      updateEventState({
        ...eventState,
        loading: true,
        status: 'pending',
      });

      setTimeout(async () => {
        if (fetchId === fetchIdRef.current) {
          try {
            let response;
            if (filterEntity) {
              response = await authAxios.get(
                `api/v1/entities/${filterEntity.uuid}/events?per_page=${pageSize}&page=${
                  pageIndex + 1
                }`
              );
            } else {
              response = await authAxios.get(
                `api/v1/events?per_page=${pageSize}&page=${pageIndex + 1}`
              );
            }
            const events = response?.data?.data || [];
            const total = response?.data?.meta?.total ?? 0;
            updateEventState({
              ...eventState,
              items: events,
              total,
              pageCount: Math.ceil(total / pageSize),
              loading: false,
              status: 'resolved',
            });
          } catch (error) {
            appsignal.sendError(error);
            console.log(error);
            updateEventState({
              ...eventState,
              items: [],
              total: 0,
              pageCount: 0,
              loading: false,
              error: 'Something went wrong',
              status: 'rejected',
            });
            const { message, code } = getErrorResponsePayload(error);
            code !== 401 &&
              toast({
                render: (props) => (
                  <CustomToast
                    status="error"
                    title={message ? message : `Something went wrong`}
                    description={!message && 'Please try again later'}
                    onClose={props.onClose}
                  />
                ),
              });
          }
        }
      }, 0);
    },
    [authAxios, filterEntity]
  );

  const normalizedData = (data) => {
    if (data?.length > 0) {
      return data.map((item) => {
        const startDate = moment(item.attributes.startTime)
          .tz(item.attributes.timeZone)
          .format('DD/MM/YYYY');
        const startTime = moment(item.attributes.startTime)
          .tz(item.attributes.timeZone)
          .format('hh:mm A');

        return {
          ...item,
          eventId: item.id,
          exampleForNestedObject: {
            eventSinglePageUrl: '/events/' + item.id,
          },
          name: item.attributes.name,
          venueName: item.attributes.venueName,
          eventStartDate: startDate,
          eventStartTime: startTime,
          status: item.attributes.status,
        };
      });
    }
    return [];
  };

  const formattedData = useMemo(() => normalizedData(items), [items]);

  return (
    <EventsListTable
      columns={columns}
      data={formattedData}
      fetchData={fetchData}
      isLoading={loading}
      pageCount={pageCount}
      total={total}
    />
  );
};

export default EventsList;
