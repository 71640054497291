import React, { useState, createContext, useContext } from 'react';
import axios from 'axios';
// import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { AuthContext } from './AuthContext';

const HttpContext = createContext();
const { Provider } = HttpContext;

// test commit comment- remove

const HttpProvider = ({ children }) => {
  const authContext = useContext(AuthContext);
  const [dashboardRender, setDashboardRender] = useState({
    location: '',
    prev: '',
  });

  function updateDashboardRenderHandler(param) {
    setDashboardRender(param);
  }

  const publicAxios = axios.create({
    baseURL:
      process.env.REACT_APP_API_URL_AXIOS ||
      window.env?.REACT_APP_API_URL_AXIOS,
    headers: {
      'Content-Type': 'application/json',
      'ngrok-skip-browser-warning':
        (process.env.NODE_ENV || window.env?.REACT_APP_NODE_ENV) ==
        'development',
    },
  });

  const authAxios = axios.create({
    baseURL:
      process.env.REACT_APP_API_URL_AXIOS ||
      window.env?.REACT_APP_API_URL_AXIOS,
    headers: {
      'X-Auth-Token': authContext.getAccessToken(),
      'Content-Type': 'application/json',
      'ngrok-skip-browser-warning':
        (process.env.NODE_ENV || window.env?.REACT_APP_NODE_ENV) ==
        'development',
    },
  });

  authAxios.interceptors.request.use(
    async (config) => {
      config.headers.Authorization = `Bearer ${authContext.getAccessToken()}`;
      // console.log(config);
      // config.headers[`X-Auth-Token`] = authContext.getAccessToken();
      return config;
    },
    (error) => {
      console.log('req nece', error);
      return Promise.reject(error);
    }
  );

  authAxios.interceptors.response.use(
    (response) => {
      // console.log(response);
      return response;
    },
    async (error) => {
      console.log('response nece', error);
      if (
        error.response.status === 401 &&
        error.response.data.message === 'Expired JWT Token'
      ) {
        console.log('401 expired jwt token');
        // window.location.reload();
      }
      //  if (error.response.status === 403) {
      //    if (
      //      error.response.data.message &&
      //      error.response.data.message === 'LOGOUT_ACTION'
      //    ) {
      //      authContext && authContext.logout();
      //    }
      //  }
      return Promise.reject(error);
    }
  );

  // createAuthRefreshInterceptor(authAxios, authContext.getNewTokenForRequest, {
  //   skipWhileRefreshing: false,
  // });

  return (
    <Provider
      value={{
        authAxios,
        publicAxios,
        dashboardRender,
        updateDashboardRender: updateDashboardRenderHandler,
      }}
    >
      {children}
    </Provider>
  );
};

export { HttpContext, HttpProvider };
