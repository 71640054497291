import { useState } from 'react';
import { VStack } from '@chakra-ui/react';
import CustomCheckbox from './CustomCheckbox';

function CustomCheckboxGroup({ options, onChange, defaultChecked }) {
  const [selectedOption, setSelectedOption] = useState(defaultChecked);

  // Toggle the selected state of an option
  const handleToggle = (value) => {
    onChange(value);
    setSelectedOption(value);
  };

  return (
    <VStack align="start" spacing={4}>
      {options.map(({ value, label, description }) => (
        <CustomCheckbox
          key={value}
          label={label}
          description={description}
          isChecked={selectedOption === value}
          onChange={() => handleToggle(value)}
        />
      ))}
    </VStack>
  );
}

export default CustomCheckboxGroup;
