import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  VStack,
  Heading,
  Text,
  Box,
  Stack,
  useToast,
  Input,
  Flex,
} from '@chakra-ui/react';
import CustomCheckboxGroup from '../common/CustomCheckboxGroup';
import { MeContext } from '../../context/MeContext';
import {
  CustomReactSelect,
  formatOptionLabelFilterEntity,
} from '../../components/common/CustomReactSelect';
import CustomToast from '../../common/CustomToast';
import { useContext, useEffect, useState } from 'react';
import { HttpContext } from '../../context/HttpContext';
import appsignal from '../../appsignal';
import { CustomSendIcon } from '../../theme/icons/CustomSendIcon';
import * as yup from 'yup';

const SendInvitationModal = (props) => {
  const { fetchData } = props;
  const { authAxios } = useContext(HttpContext);
  const meCtx = useContext(MeContext);
  const { account, entities } = meCtx.state;
  const inviter = account;
  const inviterRole = inviter.role;
  const toast = useToast();
  const [inviteeEmail, setInviteeEmail] = useState('');
  const [inviteSent, setInviteSent] = useState(false);
  const [inviteeRole, setInviteeRole] = useState(
    inviterRole === 'org_admin' ? 'org_admin' : 'ent_admin'
  );
  const [inviteeEntity, setInviteeEntity] = useState(
    inviterRole === 'org_admin' ? null : inviter.entity
  );
  const [entitiesList, setEntitiesList] = useState([]);
  const [emailError, setEmailError] = useState('');
  const [entityError, setEntityError] = useState('');
  const roles = [
    {
      value: 'org_admin',
      label: 'Organisation Admin',
      description: 'Manage organization settings, users, and entity access',
    },
    {
      value: 'ent_admin',
      label: 'Entity Admin',
      description: 'Manage entity settings and users. Restricted to one entity',
    },
  ];

  const emailSchema = yup.object().shape({
    email: yup
      .string()
      .email('Please enter a valid email address')
      .required('Email is required'),
  });

  const validateEmail = async (email) => {
    try {
      await emailSchema.validate({ email });
      setEmailError('');
      return true;
    } catch (err) {
      setEmailError(err.message);
      return false;
    }
  };

  const validateEntity = async (entity) => {
    if (inviteeRole === 'ent_admin' && !entity) {
      setEntityError('Please select an entity');
      return false;
    }
    setEntityError('');
    return true;
  };

  const onEmailChange = async (e) => {
    const email = e.target.value;
    setInviteeEmail(email);
    await validateEmail(email);
  };

  const onEntityChange = async (entity) => {
    setInviteeEntity(entity);
    await validateEntity(entity);
  };

  const onSubmit = async () => {
    const isEmailValid = await validateEmail(inviteeEmail);
    if (!isEmailValid) {
      toast({
        render: (props) => (
          <CustomToast
            status="error"
            title="Invalid Email"
            description={emailError}
            onClose={props.onClose}
          />
        ),
      });
      return;
    }

    const isEntityValid = await validateEntity(inviteeEntity);
    if (!isEntityValid) {
      toast({
        render: (props) => (
          <CustomToast
            status="error"
            title="No Entity Selected"
            description={entityError}
            onClose={props.onClose}
          />
        ),
      });
      return;
    }

    try {
      await authAxios.post(`accounts/invitation`, {
        invitation: {
          email: inviteeEmail,
          role: inviteeRole,
          entity_id: inviteeEntity?.value?.id,
          company_name: inviteeEntity?.value?.name,
        },
      });
      setInviteSent(true);
      props.onClose();
      toast({
        render: (props) => (
          <CustomToast
            status="success"
            title="Invitation sent."
            description="You have successfully sent an invitation."
            onClose={props.onClose}
            id={props.id}
          />
        ),
      });
    } catch (onError) {
      appsignal.sendError(onError);
      const errors = onError.response.data.errors;
      if (errors) {
        errors.forEach((error) => {
          toast({
            render: (props) => (
              <CustomToast
                status="error"
                title={error}
                description=""
                onClose={props.onClose}
              />
            ),
          });
        });
      }
    }
  };

  useEffect(() => {
    if (inviteSent) {
      fetchData({ inviteSent: true });
    }
    setInviteSent(false);
  }, [fetchData, inviteSent]);

  useEffect(() => {
    let entitiesArray = [];
    if (entities) {
      entities.sort((a, b) => a.name.localeCompare(b.name));
      entities.forEach((item) => {
        entitiesArray.push({
          label: item.name,
          value: item,
        });
      });
    }
    setEntitiesList([...entitiesArray]);
  }, [entities]);

  return (
    <Modal onClose={props.onClose} isOpen={props.isOpen} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Flex direction="column" align="center" gap={3} pt={3}>
            <Box mb={2}>
              <CustomSendIcon color="#EC675A" width="50px" height="50px" />
            </Box>
            <Heading fontSize={{ base: '24px', lg: '32px' }} lineHeight="130%">
              New invitation
            </Heading>
          </Flex>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing="20px" alignItems="flex-start">
            <Text textAlign="center" fontSize="18px" px={2}>
              Enter the email of the person you want to invite to your team.
            </Text>
            <Box w="full">
              <Input
                variant="filled"
                id="inviteeEmail"
                type="text"
                placeholder="Enter email"
                w="full"
                onChange={onEmailChange}
                mb={2}
                isInvalid={!!emailError}
              />
              {emailError && (
                <Text pl={4} color="red.500" fontSize="sm">
                  {emailError}
                </Text>
              )}
            </Box>
          </VStack>
          {inviterRole === 'org_admin' && entities.length > 1 && (
            <VStack spacing="20px" mt={6} mb={4}>
              <Text textAlign="center" fontSize="18px" px={2}>
                Choose role of the person you want to invite to your team.
              </Text>
              <Box w="full">
                <CustomCheckboxGroup
                  options={roles}
                  onChange={(role) =>
                    setInviteeRole((prev) => (prev === role ? prev : role))
                  }
                  defaultChecked={inviteeRole}
                />
                {inviteeRole === 'ent_admin' && (
                  <Box my={5} mx={1}>
                    <CustomReactSelect
                      options={entitiesList}
                      formatOptionLabel={formatOptionLabelFilterEntity}
                      onChange={(entity) => {
                        onEntityChange(entity);
                      }}
                      value={inviteeEntity}
                      placeholder="All entities"
                      id="entity"
                      isInvalid={!!entityError}
                    />
                    {entityError && (
                      <Text ml={6} pl={4} color="red.500" fontSize="sm">
                        {entityError}
                      </Text>
                    )}
                  </Box>
                )}
              </Box>
            </VStack>
          )}
        </ModalBody>
        <ModalFooter>
          <Stack
            spacing="10px"
            direction={{ base: 'column', sm: 'row' }}
            width={{ base: 'full', sm: 'auto' }}
          >
            <Button
              onClick={props.onClose}
              alt="Cancel"
              variant="secondary"
              width={{ base: 'full', sm: 'auto' }}
            >
              Cancel
            </Button>
            <Button
              alt="Send"
              width={{ base: 'full', sm: 'auto' }}
              onClick={onSubmit}
              isDisabled={!!emailError}
            >
              Send
            </Button>
          </Stack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SendInvitationModal;
