import { useContext, useEffect, useState, useRef, useMemo } from 'react';
import {
  useColorModeValue,
  Text,
  useToast,
  useDisclosure,
  Grid,
  VStack,
  FormControl,
  Checkbox,
  Collapse,
  FormLabel,
  Input,
  Box,
  GridItem,
  Stack,
  Button,
  Flex,
  Spinner,
  Center,
  FormHelperText,
  Textarea,
  Tooltip,
  Radio,
  RadioGroup,
  IconButton,
  Link,
  Select,
  FormErrorMessage,
  UnorderedList,
  ListItem,
} from '@chakra-ui/react';
import { MeContext } from '../../context/MeContext';
import { useForm, useFieldArray } from 'react-hook-form';
import { HttpContext } from '../../context/HttpContext';
import placeholderIcon from '../../assets/images/icon.png';
import { getErrors } from '../../utils/ajax';

import appsignal from '../../appsignal';
import { getErrorResponsePayload } from '../../utils/ajax';
import { useNavigate } from 'react-router-dom';
import {
  CustomReactSelect,
  formatOptionLabelFilterGroupTag,
  formatOptionLabelFilterPassTemplate,
} from '../../components/common/CustomReactSelect';
import PreviewPassNotificationsApple from '../../components/client/pass-templates/PreviewPassNotificationsApple';
import PreviewPassNotificationsAndroid from '../../components/client/pass-templates/PreviewPassNotificationsAndroid';
import CustomToast from '../../common/CustomToast';
import AlertDialogWrapper from '../../components/common/AlertDialogWrapper';
import { CustomExclamationMarkInverseIcon } from '../../theme/icons/CustomExclamationMarkInverseIcon';
import { CustomPlusIcon } from '../../theme/icons/CustomPlusIcon';
import { CustomCloseIcon } from '../../theme/icons/CustomCloseIcon';
import { timezoneList } from '../../utils/timezones';
import moment from 'moment';
import { CustomCsvDownloadIcon } from '../../theme/icons/CustomCsvDownloadIcon';
import CustomDatePicker from '../../components/common/CustomDatePicker/CustomDatePicker';
import CustomTimePicker from '../../components/common/CustomTimePicker/CustomTimePicker';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { CustomMinusIcon } from '../../theme/icons/CustomMinusIcon';

const MessagesCreatePage = () => {
  const toast = useToast();
  const { authAxios } = useContext(HttpContext);
  const [isLoading, setIsLoading] = useState(false);
  const [passTemplatesList, setPassTemplatesList] = useState([]);
  const [groupTagsList, setGroupTagsList] = useState([]);
  const [recipientType, setRecipientType] = useState('1');
  const [messageHeader, setMessageHeader] = useState('');
  const [iconLogo, setIconLogo] = useState('');
  const modeBoxBg = useColorModeValue('white', 'secondaryDark');
  const meCtx = useContext(MeContext);
  const { filterEntity, entities } = meCtx.state;
  const [filterEntityValue, setFilterEntityValue] = useState('');
  const [totalPasses, setTotalPasses] = useState(0);
  const modeFormControlBg = useColorModeValue('#EFEFEF', 'primaryDark03');
  const modeFormLightBg = useColorModeValue('white', '');
  const [formData, setFormData] = useState(null);
  const navigate = useNavigate();

  const bgTooltip = useColorModeValue('secondaryDark', '#dddddd');
  const textTooltip = useColorModeValue('#ffffff', 'primaryDark');
  const [variableNames, setVariableNames] = useState([]);
  const timezones = useMemo(timezoneList, []);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const validationSchema = Yup.object()
    .shape({
      batchName: Yup.string(),
      tag: Yup.string(),
      message: Yup.string()
        .required('Message is required')
        .min(10, 'Message cannot be less than 10 characters'),
      scheduledDate: Yup.date()
        .nullable()
        .required('Scheduled date is required')
        .test(
          'valid-schedule',
          'Selected date/time must be between 1 hour and 365 days from now',
          function (value) {
            if (!value) return false;

            const scheduledAt = buildScheduledAt({
              scheduledDate: value,
              scheduledTime: this.parent.scheduledTime,
              timezone: this.parent.timezone,
            });

            if (!scheduledAt) return true; // Skip validation if time/timezone not yet selected

            const now = new Date();
            const minTime = new Date(now.getTime() + 60 * 60 * 1000); // 1 hour from now
            const maxTime = new Date(now.getTime() + 365 * 24 * 60 * 60 * 1000); // 365 days from now

            const scheduledDate = new Date(scheduledAt);
            return scheduledDate >= minTime && scheduledDate <= maxTime;
          }
        ),
      scheduledTime: Yup.string()
        .nullable()
        .required('Scheduled time is required')
        .matches(
          /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]\s?(AM|PM)$/i,
          'Scheduled time must be in HH:MM AM/PM format'
        ),
      timezone: Yup.string().required('Timezone is required'),
      recipientType: Yup.string().required('Recipient type is required'),
      recipients: Yup.array().when('recipientType', {
        is: '1',
        then: (schema) =>
          schema
            .of(
              Yup.object().shape({
                passId: Yup.string().required('Pass ID is required'),

                variables: Yup.lazy(() =>
                  Yup.object().shape(
                    variableNames.reduce((acc, varName) => {
                      acc[varName] = Yup.string().required(
                        `${varName} is required`
                      );
                      return acc;
                    }, {})
                  )
                ),
              })
            )
            .required('Recipients are required')
            .min(1, 'At least one recipient is required'),
        otherwise: (schema) => schema.notRequired(),
      }),
      csvFile: Yup.mixed().when('recipientType', {
        is: '3',
        then: (schema) =>
          schema
            .required('CSV file is required')
            .test('fileType', 'Only .csv files are accepted', (value) => {
              return value?.[0]?.type === 'text/csv';
            }),
        otherwise: (schema) => schema.notRequired(),
      }),
      byPassTemplate: Yup.boolean(),
      byGroupTag: Yup.boolean(),
      byEntity: Yup.boolean(),
      passTemplate: Yup.mixed().when(['recipientType', 'byPassTemplate'], {
        is: (recipientType, byPassTemplate) =>
          recipientType === '2' && byPassTemplate === true,
        then: (schema) => schema.required('Please select a pass template'),
        otherwise: (schema) => schema.nullable(),
      }),
      groupTag: Yup.mixed().when(['recipientType', 'byGroupTag'], {
        is: (recipientType, byGroupTag) =>
          recipientType === '2' && byGroupTag === true,
        then: (schema) => schema.required('Please select a group tag'),
        otherwise: (schema) => schema.nullable(),
      }),
    })
    .test('at-least-one-filter', null, function (values) {
      if (values.recipientType === '2') {
        const hasFilter =
          values.byPassTemplate || values.byGroupTag || values.byEntity;
        if (!hasFilter) {
          return this.createError({
            path: 'recipientType',
            message: 'Please select at least one filter option',
          });
        }
      }
      return true;
    });

  const {
    register,
    reset,
    trigger,
    handleSubmit,
    control,
    watch,
    formState: { isSubmitting, errors },
    setValue,
    getValues,
  } = useForm({
    resolver: yupResolver(validationSchema),
    shouldUnregister: true,
    defaultValues: {
      message: '',
      tag: '',
      scheduledDate: null,
      scheduledTime: '',
      timezone: moment.tz.guess(),
      batchName: '',
      recipientType: '1',
      recipients: [{ passId: '', variables: {} }],
      csvFile: null,
      byPassTemplate: false,
      byGroupTag: false,
      byEntity: false,
      passTemplate: null,
      groupTag: null,
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'recipients',
  });

  const watchMessage = watch('message');
  const watchCsvFile = watch('csvFile');
  const watchByPassTemplate = watch('byPassTemplate');
  const watchByGroupTag = watch('byGroupTag');
  const watchByEntity = watch('byEntity');
  const watchPassTemplate = watch('passTemplate');
  const watchGroupTag = watch('groupTag');
  const watchRecipientType = watch('recipientType');
  const [scheduledDate, setScheduledDate] = useState(null);
  const [scheduledTime, setScheduledTime] = useState('');
  const [showExamples, setShowExamples] = useState(false);

  const previewData = {
    iconLogo: iconLogo || placeholderIcon,
    notificationHeader: messageHeader || 'Notification header',
    messageText: watchMessage,
    smallerDisplay: true,
  };

  const buildScheduledAt = (data) => {
    if (!data.scheduledDate || !data.scheduledTime || !data.timezone) {
      return null;
    }

    const date = moment(data.scheduledDate).format('YYYY-MM-DD');
    const time = moment(data.scheduledTime, 'hh:mm A').format('HH:mm:ss');
    const dateTime = moment.tz(
      `${date} ${time}`,
      'YYYY-MM-DD HH:mm:ss',
      data.timezone
    );
    return dateTime.toISOString();
  };

  const buildBasePayload = (data) => {
    const payload = {
      batch: {
        message: data.message,
        scheduledAt: buildScheduledAt(data),
      },
    };

    if (data.batchName) payload.batch.name = data.batchName;
    if (data.tag) payload.batch.tag = data.tag;

    return payload;
  };

  const handleSend = async (data) => {
    onClose();
    try {
      const basePayload = buildBasePayload(data);

      if (data.recipientType === '1' || data.recipientType === '2') {
        if (data.recipientType === '1') {
          basePayload.batch.recipients = data.recipients;
        } else if (data.recipientType === '2') {
          if (data.byPassTemplate) {
            basePayload.batch.segments = [
              {
                passTemplateId: data.passTemplate?.value?.uuid,
              },
            ];
          }
          if (data.byGroupTag) {
            basePayload.batch.segments = [
              {
                groupTag: data.groupTag?.label,
              },
            ];
          }
          if (data.byEntity) {
            basePayload.batch.segments = [
              {
                entityId: filterEntityValue.uuid,
              },
            ];
          }
        }
        await authAxios.post(
          `api/v1/entities/${filterEntityValue.uuid}/messaging/wallet`,
          basePayload
        );
      } else if (
        data.recipientType === '3' &&
        data.csvFile &&
        data.csvFile[0]
      ) {
        const formData = new FormData();
        formData.append('csv', data.csvFile[0]);
        formData.append('message', basePayload.batch.message);
        formData.append('scheduledAt', basePayload.batch.scheduledAt);

        if (basePayload.batchName)
          formData.append('name', basePayload.batch.name);
        if (basePayload.batch.tag)
          formData.append('tag', basePayload.batch.tag);

        const formDataObj = Object.fromEntries(formData.entries());
        console.log('FormData as object:', formDataObj);
        await authAxios.post(
          `api/v1/entities/${filterEntityValue.uuid}/messaging/wallet`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );
      }
      toast({
        render: ({ onClose }) => (
          <CustomToast
            status="success"
            title="Message batch created"
            description="Your wallet notifications are currently being processed. We will notify you if there are any issues."
            onClose={onClose}
          />
        ),
      });
      reset();
      navigate('/messaging');
    } catch (error) {
      console.log(error);
      const errors = getErrors(error);
      errors?.forEach((errorMessage) => {
        toast({
          render: ({ onClose }) => (
            <CustomToast
              status="error"
              title="Submission failed"
              description={
                errorMessage ? errorMessage : 'Please try again later.'
              }
              onClose={onClose}
            />
          ),
        });
      });
    }
  };

  const onSubmit = async (data) => {
    setFormData(data);
    onOpen();
  };

  const sampleCsvContent = `pass_id,name,ticket_number
6CA1339D,John Doe,ABC123
E7178F47,Jane Smith,XYZ789`;

  const handleDownloadSampleCsv = () => {
    const csvContent =
      'data:text/csv;charset=utf-8,' + encodeURIComponent(sampleCsvContent);
    const link = document.createElement('a');
    link.setAttribute('href', csvContent);
    link.setAttribute('download', 'sample_recipients.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    const getTotalPasses = async () => {
      try {
        if (!filterEntityValue) {
          return;
        }

        const shouldFetchPasses =
          (watchByPassTemplate && watchPassTemplate?.value?.uuid) ||
          (watchByGroupTag && watchGroupTag?.label) ||
          (watchByEntity && filterEntityValue?.uuid);

        if (!shouldFetchPasses) {
          setTotalPasses(0);
          return;
        }

        const params = new URLSearchParams();
        params.append('fields', 'status');
        params.append('status[]', 'active');
        params.append('status[]', 'issued');

        if (watchByPassTemplate && watchPassTemplate?.value?.uuid) {
          params.append('passTemplateUuid', watchPassTemplate.value.uuid);
        }
        if (watchByGroupTag && watchGroupTag?.label) {
          params.append('groupTag', watchGroupTag.label);
        }

        const response = await authAxios.get(
          `api/v1/entities/${filterEntityValue.uuid}/passes?${params.toString()}`
        );
        const totalPasses = response?.data?.meta?.totalCount ?? 0;
        setTotalPasses(totalPasses);
      } catch (error) {
        console.log(error);
      }
    };

    if (recipientType === '1') {
      setTotalPasses(fields.length);
    } else if (recipientType === '2') {
      getTotalPasses();
    } else if (recipientType === '3') {
      setTotalPasses(0);
    }
  }, [
    authAxios,
    filterEntityValue,
    watchPassTemplate,
    watchGroupTag,
    watchByEntity,
    recipientType,
  ]);

  useEffect(() => {
    const getData = async () => {
      try {
        if (!filterEntityValue) {
          return;
        }
        let response;
        response = await authAxios.get(
          `entities/${filterEntityValue.uuid}/passes/templates`
        );

        const data = response?.data?.data;
        data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        let templates = [];
        if (data.length === 0) {
          setPassTemplatesList([]);
          return;
        }
        data.forEach((item) => {
          templates.push({
            label: item.name,
            value: item,
          });
        });
        setPassTemplatesList(templates);
        // if no filter is set always return to All pass templates default
      } catch (error) {
        appsignal.sendError(error);
        console.log(error);
        const { message, code } = getErrorResponsePayload(error);
        code !== 401 &&
          toast({
            render: (props) => (
              <CustomToast
                status="error"
                title={message ? message : `Something went wrong`}
                description={!message && 'Please try again later'}
                onClose={props.onClose}
              />
            ),
          });
      }
    };

    getData();

    return () => {
      setPassTemplatesList([]);
    };
  }, [authAxios, filterEntityValue]);

  useEffect(() => {
    const regex = /%\{(\w+)\}/g;
    const names = [];
    let match;
    while ((match = regex.exec(watchMessage)) !== null) {
      names.push(match[1]);
    }
    setVariableNames(names);

    if (watchRecipientType === '1') {
      fields.forEach((field, index) => {
        const currentVariables =
          getValues(`recipients.${index}.variables`) || {};
        const updatedVariables = {};
        variableNames.forEach((name) => {
          updatedVariables[name] = currentVariables[name] || '';
        });
        setValue(`recipients.${index}.variables`, updatedVariables);
      });
    }
  }, [watchMessage, fields, setValue]);

  // fetch all group tags for select group tags dropdown
  useEffect(() => {
    const getGroupTags = async () => {
      try {
        if (!filterEntityValue) {
          return;
        }
        let response;
        response = await authAxios.get(
          `api/v1/entities/${filterEntityValue.uuid}/passes?fields=groupTag`
        );

        const data = response.data?.data;
        const groupTags = data.map((item) => {
          if (item.attributes.groupTag) {
            return {
              label: item.attributes.groupTag,
            };
          } else {
            return undefined;
          }
        });
        const filteredGroupTags = groupTags.filter(
          (item) => item !== undefined
        );
        const uniqueGroupTags = Array.from(
          new Set(filteredGroupTags.map((a) => a.label))
        ).map((label) => {
          return filteredGroupTags.find((a) => a.label === label);
        });
        setGroupTagsList(uniqueGroupTags);
      } catch (error) {
        appsignal.sendError(error);
        console.log(error);
      }
    };
    getGroupTags();
  }, [authAxios, filterEntityValue]);

  useEffect(() => {
    if (entities) {
      setFilterEntityValue(entities.length > 1 ? filterEntity : entities[0]);
      setValue('passTemplate', null);
      setValue('groupTag', null);
      setValue('byPassTemplate', false);
      setValue('byGroupTag', false);
      setValue('byEntity', false);
    }
  }, [entities, filterEntity, setValue]);

  useEffect(() => {
    if (variableNames.length > 0 && recipientType === '2') {
      setValue('recipientType', '1');
    }
  }, [variableNames]);

  useEffect(() => {
    setValue('recipientType', recipientType);
  }, [recipientType, setValue]);

  useEffect(() => {
    setValue('scheduledDate', scheduledDate);
    if (scheduledDate) {
      trigger('scheduledDate');
    }
  }, [scheduledDate, scheduledTime, setValue]);

  useEffect(() => {
    setValue('scheduledTime', scheduledTime);
    if (scheduledTime) {
      trigger('scheduledTime');
    }
  }, [scheduledTime, setValue]);

  const isEntitySelected = !!filterEntityValue;

  return (
    <>
      {!isLoading ? (
        <>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid
              templateColumns={{
                base: '100%',
                xl: '45% 55%',
              }}
              gap={6}
              mr={{ base: '0px', md: '30px' }}
            >
              <Box
                borderRadius="15px"
                padding="20px 30px"
                minW={{ base: 'none', md: 'fit-content' }}
                top="20px"
                width="100%"
                background={modeBoxBg}
                height="fit-content"
              >
                <VStack align="flex-start" spacing={4}>
                  {/* Batch Name */}
                  <Text textStyle="bodyBold" color="secondaryDark" pt="20px">
                    Batch Name
                  </Text>
                  <FormControl isInvalid={errors.batchName}>
                    <Input
                      placeholder="Enter batch name"
                      {...register('batchName')}
                      isDisabled={!isEntitySelected}
                      autoComplete="off"
                    />
                    {errors.batchName && (
                      <FormErrorMessage mt={2}>
                        {errors.batchName.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>

                  {/* Tag */}
                  <Text textStyle="bodyBold" color="secondaryDark" pt="20px">
                    Tag
                  </Text>
                  <FormControl isInvalid={errors.tag}>
                    <Input
                      placeholder="Enter tag"
                      {...register('tag')}
                      isDisabled={!isEntitySelected}
                    />
                    <FormHelperText mt={2}>
                      Assign a custom label to this notifications batch. This
                      tag helps you categorise and identify your messages for
                      future reference or reporting.
                    </FormHelperText>
                    {errors.tag && (
                      <FormErrorMessage mt={2}>
                        {errors.tag.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>

                  {/* Message */}
                  <Tooltip
                    label="Message header and icon are inherited from each pass's
                    template."
                    hasArrow
                    placement="top"
                    fontSize="12px"
                    bg={bgTooltip}
                    color={textTooltip}
                    w="205px"
                    borderRadius="6px"
                    textAlign="center"
                    p="10px"
                  >
                    <Box cursor="pointer" display="flex" alignItems="center">
                      <Text textStyle="bodyBold" color="secondaryDark">
                        Message
                      </Text>
                      <CustomExclamationMarkInverseIcon
                        boxSize="14px"
                        mode={'light'}
                        ml={1}
                      />
                    </Box>
                  </Tooltip>

                  <FormControl isInvalid={errors.message}>
                    <Textarea
                      id="message"
                      placeholder="Enter your message here"
                      bg={modeFormControlBg}
                      {...register('message')}
                      isDisabled={!isEntitySelected}
                    />
                    <FormHelperText mt={2}>
                      You can personalize your message using variables. Use the
                      format{' '}
                      <span
                        style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}
                      >
                        %&#123;variable_name&#125;
                      </span>{' '}
                      where words are connected with underscores.
                    </FormHelperText>
                    <Flex align="center" mt={1} mb={2}>
                      <Button
                        variant="link"
                        onClick={() => setShowExamples(!showExamples)}
                        leftIcon={
                          showExamples ? (
                            <CustomMinusIcon />
                          ) : (
                            <CustomPlusIcon />
                          )
                        }
                        size="sm"
                        fontSize="13px"
                        fontWeight="bold"
                        height="24px"
                      >
                        {showExamples ? 'Hide Examples' : 'Show Examples'}
                      </Button>
                    </Flex>
                    <Collapse in={showExamples} animateOpacity>
                      <UnorderedList fontSize="12px" fontWeight="normal">
                        <ListItem>
                          Hi{' '}
                          <Text as="span" fontWeight="semibold">
                            %&#123;first_name&#125;
                          </Text>
                          ! You've earned{' '}
                          <Text as="span" fontWeight="semibold">
                            %&#123;points&#125;
                          </Text>{' '}
                          points on your last visit
                        </ListItem>
                        <ListItem>
                          Your VIP ticket #
                          <Text as="span" fontWeight="semibold">
                            %&#123;ticket_number&#125;
                          </Text>{' '}
                          for{' '}
                          <Text as="span" fontWeight="semibold">
                            %&#123;event_name&#125;
                          </Text>{' '}
                          is ready
                        </ListItem>
                        <ListItem>
                          Congratulations{' '}
                          <Text as="span" fontWeight="semibold">
                            %&#123;first_name&#125;
                          </Text>
                          ! You've reached{' '}
                          <Text as="span" fontWeight="semibold">
                            %&#123;tier_name&#125;
                          </Text>{' '}
                          status
                        </ListItem>
                      </UnorderedList>
                    </Collapse>
                    {errors.message && (
                      <FormErrorMessage mt={2}>
                        {errors.message.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>

                  {/* Scheduled At */}
                  <Box>
                    <Text
                      textStyle="bodyBold"
                      color="secondaryDark"
                      mt={3}
                      mb={4}
                    >
                      Send At
                    </Text>
                    <FormControl
                      isInvalid={errors.scheduledDate?.message?.includes(
                        'Selected date/time must be between'
                      )}
                    >
                      <Box
                        w="full"
                        p="19px"
                        bg={modeFormControlBg}
                        borderRadius="15px"
                      >
                        <Flex
                          direction={{
                            base: 'column',
                            md: 'row',
                          }}
                          justifyContent="space-between"
                          gap={3}
                          mb={3}
                        >
                          <Box w="full">
                            <FormControl
                              isInvalid={
                                errors.scheduledDate &&
                                !errors.scheduledDate.message?.includes(
                                  'Selected date/time must be between'
                                )
                              }
                            >
                              <CustomDatePicker
                                placementTop
                                setDate={setScheduledDate}
                                dateValue={scheduledDate}
                                startDate
                                minBookingDate={new Date()}
                                isDisabled={!filterEntityValue}
                              />
                              {errors.scheduledDate &&
                                !errors.scheduledDate.message?.includes(
                                  'Selected date/time must be between'
                                ) && (
                                  <FormErrorMessage mt={2}>
                                    {errors.scheduledDate.message}
                                  </FormErrorMessage>
                                )}
                            </FormControl>
                          </Box>
                          <Box w="full">
                            <FormControl isInvalid={errors.scheduledTime}>
                              <CustomTimePicker
                                timeValue={scheduledTime}
                                setTimeValue={setScheduledTime}
                                placementTop
                                startTime
                                isDisabled={!filterEntityValue}
                              />
                              {errors.scheduledTime && (
                                <FormErrorMessage mt={2}>
                                  {errors.scheduledTime.message}
                                </FormErrorMessage>
                              )}
                            </FormControl>
                          </Box>
                        </Flex>
                        <Box>
                          <FormControl isInvalid={errors.timezone}>
                            <Select
                              {...register('timezone')}
                              variant="filledForDarkBg"
                              isDisabled={!filterEntityValue}
                            >
                              {timezones.map((timezone, index) => {
                                return (
                                  <option key={index} value={timezone.name}>
                                    {timezone.key}
                                  </option>
                                );
                              })}
                            </Select>
                            {errors.timezone && (
                              <FormErrorMessage mt={2}>
                                {errors.timezone.message}
                              </FormErrorMessage>
                            )}
                          </FormControl>
                        </Box>
                      </Box>
                      {errors.scheduledDate?.message?.includes(
                        'Selected date/time must be between'
                      ) && (
                        <FormErrorMessage mt={2}>
                          {errors.scheduledDate.message}
                        </FormErrorMessage>
                      )}
                    </FormControl>
                    <FormControl>
                      <FormHelperText mt={2}>
                        You can schedule messages between 1 hour from now and up
                        to 365 days in advance. Messages will be sent according
                        to the selected timezone.
                      </FormHelperText>
                    </FormControl>
                  </Box>

                  {/* Recipients */}
                  <Box w="full" mt={3}>
                    <Text size="lg" textStyle="bodyBold" color="secondaryDark">
                      Recipients
                    </Text>
                    {variableNames.length > 0 && (
                      <Text color="brand" fontSize="sm" mt={1} mb={2}>
                        Pass filtering is unavailable when using variables in
                        your message
                      </Text>
                    )}
                    <RadioGroup
                      value={recipientType}
                      onChange={(value) => {
                        setRecipientType(value);
                        setValue('passTemplate', null);
                        setValue('groupTag', null);
                        setValue('byPassTemplate', false);
                        setValue('byGroupTag', false);
                      }}
                      display="flex"
                      flexDirection="column"
                      gap={2}
                      isDisabled={!isEntitySelected}
                      mb={6}
                      mt={4}
                    >
                      <Radio value="1" colorScheme="red">
                        Manual selection
                      </Radio>
                      <Box>
                        <Radio
                          value="2"
                          colorScheme="red"
                          isDisabled={
                            variableNames.length > 0 || !isEntitySelected
                          }
                        >
                          Pass filtering
                        </Radio>
                      </Box>
                      <Radio value="3" colorScheme="red">
                        CSV Upload
                      </Radio>
                    </RadioGroup>

                    {recipientType === '2' && (
                      <Box
                        p="19px"
                        w="full"
                        bg={modeFormControlBg}
                        borderRadius="15px"
                      >
                        <Text mb={4} fontSize="14px" color="secondaryDark">
                          Filter recipients by selecting either an entity, a
                          pass template or a group tag. This will send the
                          message to all pass holders that match your selection
                          criteria.
                        </Text>
                        <FormControl isInvalid={!!errors.recipientType}>
                          {/* by pass template */}
                          <Box
                            bg={modeFormLightBg}
                            borderWidth="1px"
                            py={6}
                            px={3}
                            borderRadius="15px"
                          >
                            {/* By entity */}
                            <FormControl px="24px">
                              <Checkbox
                                id="byEntity"
                                {...register('byEntity')}
                                isChecked={watchByEntity}
                                onChange={(e) => {
                                  setValue('byEntity', e.target.checked);
                                  trigger('recipientType');
                                }}
                                isDisabled={
                                  !filterEntityValue ||
                                  watchByPassTemplate ||
                                  watchByGroupTag
                                }
                              >
                                <Text
                                  textStyle="bodyRegular"
                                  color="secondaryDark"
                                  fontSize="15px"
                                >
                                  By entity
                                </Text>
                              </Checkbox>
                            </FormControl>

                            {/* By pass template */}
                            <FormControl
                              px="24px"
                              mt="12px"
                              isInvalid={!!errors.passTemplate}
                            >
                              <Checkbox
                                id="byPassTemplate"
                                {...register('byPassTemplate')}
                                isChecked={watchByPassTemplate}
                                onChange={(e) => {
                                  setValue('byPassTemplate', e.target.checked);
                                  if (!e.target.checked) {
                                    setValue('passTemplate', null);
                                  }
                                  trigger('recipientType');
                                }}
                                isDisabled={
                                  !filterEntityValue ||
                                  watchByGroupTag ||
                                  watchByEntity
                                }
                              >
                                <Text
                                  textStyle="bodyRegular"
                                  color="secondaryDark"
                                  fontSize="15px"
                                >
                                  By pass template
                                </Text>
                              </Checkbox>
                              <Collapse
                                in={filterEntityValue && watchByPassTemplate}
                              >
                                <CustomReactSelect
                                  options={passTemplatesList}
                                  placeholder="Select pass template"
                                  formatOptionLabel={
                                    formatOptionLabelFilterPassTemplate
                                  }
                                  onChange={(passTemplate) => {
                                    setValue('passTemplate', {
                                      label: passTemplate.label,
                                      value: passTemplate.value,
                                    });
                                    setIconLogo(passTemplate.value.icon_image);
                                    setMessageHeader(
                                      passTemplate.value.notification_header
                                    );
                                    trigger('passTemplate');
                                  }}
                                  value={watchPassTemplate}
                                  id="passTemplateFilter"
                                  passTemplate
                                />
                              </Collapse>
                              {errors.passTemplate && (
                                <FormErrorMessage mt={2}>
                                  {errors.passTemplate.message}
                                </FormErrorMessage>
                              )}
                            </FormControl>
                            {/* by group tag */}
                            <FormControl
                              px="24px"
                              mt="12px"
                              isInvalid={!!errors.groupTag}
                            >
                              <Checkbox
                                id="byGroupTag"
                                {...register('byGroupTag')}
                                isChecked={watchByGroupTag}
                                onChange={(e) => {
                                  setValue('byGroupTag', e.target.checked);
                                  if (!e.target.checked) {
                                    setValue('groupTag', null);
                                  }
                                  trigger('recipientType');
                                }}
                                isDisabled={
                                  !filterEntityValue ||
                                  watchByPassTemplate ||
                                  watchByEntity
                                }
                              >
                                <Text
                                  textStyle="bodyRegular"
                                  color="secondaryDark"
                                  fontSize="15px"
                                >
                                  By group tag
                                </Text>
                              </Checkbox>
                              <Collapse
                                in={filterEntityValue && watchByGroupTag}
                              >
                                <CustomReactSelect
                                  options={groupTagsList}
                                  placeholder="Select group tag"
                                  formatOptionLabel={
                                    formatOptionLabelFilterGroupTag
                                  }
                                  onChange={(groupTag) => {
                                    setValue('groupTag', groupTag);
                                    trigger('groupTag');
                                  }}
                                  value={watchGroupTag}
                                  id="groupTagFilter"
                                />
                              </Collapse>
                              {errors.groupTag && (
                                <FormErrorMessage mt={2}>
                                  {errors.groupTag.message}
                                </FormErrorMessage>
                              )}
                            </FormControl>
                          </Box>
                          {errors.recipientType && (
                            <FormErrorMessage mt={2}>
                              {errors.recipientType.message}
                            </FormErrorMessage>
                          )}
                        </FormControl>
                        <Box mt={5}>
                          <Text
                            display="inline"
                            textStyle="bodyRegular"
                            color="secondaryDark"
                          >
                            Message{' '}
                          </Text>
                          <Text display="inline" textStyle="bodyBold">
                            {totalPasses}
                          </Text>
                          <Text display="inline">
                            {' '}
                            pass holder{totalPasses === 1 ? '' : 's'}
                          </Text>
                        </Box>
                      </Box>
                    )}

                    {recipientType === '1' && (
                      <FormControl>
                        <Box
                          p="19px"
                          w="full"
                          bg={modeFormControlBg}
                          borderRadius="15px"
                        >
                          <Grid gap={4}>
                            {fields.map((field, index) => (
                              <GridItem key={field.id}>
                                <Box
                                  flex="1"
                                  borderWidth="1px"
                                  p={4}
                                  borderRadius="15px"
                                  bg={modeFormLightBg}
                                >
                                  <Flex
                                    alignItems="center"
                                    justifyContent="space-between"
                                  >
                                    <Box flex="1">
                                      <Grid
                                        templateColumns={{
                                          base: '1fr',
                                          xl:
                                            variableNames.length === 0
                                              ? '1fr'
                                              : 'repeat(2, 1fr)',
                                        }}
                                        gap={2}
                                      >
                                        <GridItem>
                                          <FormControl
                                            isInvalid={
                                              !!errors?.recipients?.[index]
                                                ?.passId
                                            }
                                          >
                                            <Input
                                              placeholder="Pass ID (uuid or external ID)"
                                              {...register(
                                                `recipients.${index}.passId`
                                              )}
                                              isDisabled={!isEntitySelected}
                                            />
                                            {!!errors?.recipients?.[index]
                                              ?.passId && (
                                              <FormErrorMessage mt={2}>
                                                {
                                                  errors.recipients[index]
                                                    .passId.message
                                                }
                                              </FormErrorMessage>
                                            )}
                                          </FormControl>
                                        </GridItem>

                                        {/* Variables Input Fields */}
                                        {variableNames.length > 0 &&
                                          variableNames.map(
                                            (varName, varIndex) => (
                                              <GridItem
                                                key={`${varIndex}-${varName}`}
                                              >
                                                <FormControl
                                                  key={varName}
                                                  isInvalid={
                                                    !!errors?.recipients?.[
                                                      index
                                                    ]?.variables?.[varName]
                                                  }
                                                >
                                                  <Input
                                                    placeholder={`${varName
                                                      .split('_')
                                                      .map(
                                                        (word) =>
                                                          word
                                                            .charAt(0)
                                                            .toUpperCase() +
                                                          word.slice(1)
                                                      )
                                                      .join(' ')}`}
                                                    {...register(
                                                      `recipients.${index}.variables.${varName}`
                                                    )}
                                                  />
                                                  {!!errors?.recipients?.[index]
                                                    ?.variables?.[varName]
                                                    ?.message && (
                                                    <FormErrorMessage mt={2}>
                                                      {
                                                        errors.recipients[index]
                                                          .variables[varName]
                                                          .message
                                                      }
                                                    </FormErrorMessage>
                                                  )}
                                                </FormControl>
                                              </GridItem>
                                            )
                                          )}
                                      </Grid>
                                    </Box>
                                    {fields.length > 1 && (
                                      <IconButton
                                        icon={
                                          <CustomCloseIcon w="12px" h="12px" />
                                        }
                                        w="30px"
                                        h="30px"
                                        p={0}
                                        m={0}
                                        ml={2}
                                        onClick={() => remove(index)}
                                        aria-label="Remove recipient"
                                        borderRadius="50%"
                                        minWidth="unset"
                                      />
                                    )}
                                  </Flex>
                                </Box>
                              </GridItem>
                            ))}
                          </Grid>
                          <Button
                            leftIcon={<CustomPlusIcon />}
                            size="sm"
                            onClick={() =>
                              append({ passId: '', variables: {} })
                            }
                            mt={4}
                            isDisabled={
                              fields.length >= 10 || !isEntitySelected
                            }
                          >
                            Add Recipient
                          </Button>
                        </Box>
                        <FormHelperText my={2}>
                          You can add up to 10 recipients. For more, please use
                          CSV upload.
                        </FormHelperText>
                      </FormControl>
                    )}

                    {recipientType === '3' && (
                      <Box
                        p="19px"
                        w="full"
                        bg={modeFormControlBg}
                        borderRadius="15px"
                      >
                        <FormControl isInvalid={!!errors.csvFile}>
                          <FormLabel mb={1}>
                            Upload CSV File with Recipients
                          </FormLabel>
                          <FormHelperText mb={3}>
                            The CSV file must include a 'pass_id' column and any
                            message variables.
                          </FormHelperText>
                          <Button
                            as="label"
                            htmlFor="csv-upload"
                            cursor="pointer"
                            variant="white"
                            size="md"
                            width="full"
                            borderRadius="7px"
                            textTransform="unset"
                            fontFamily="unset"
                            borderColor={errors.csvFile ? 'error' : 'inherit'}
                            borderWidth={errors.csvFile ? '2px' : '0px'}
                            color={errors.csvFile ? 'red.500' : 'inherit'}
                          >
                            {watchCsvFile && watchCsvFile[0]
                              ? watchCsvFile[0].name
                              : 'Choose File'}
                            <input
                              id="csv-upload"
                              type="file"
                              accept=".csv"
                              style={{ display: 'none' }}
                              {...register('csvFile')}
                            />
                          </Button>
                          {errors.csvFile && (
                            <FormErrorMessage mt={3}>
                              {errors.csvFile.message}
                            </FormErrorMessage>
                          )}

                          <Flex alignItems="center" gap={1} mt={3}>
                            <CustomCsvDownloadIcon boxSize={6} color="brand" />
                            <FormHelperText>
                              <Link
                                onClick={handleDownloadSampleCsv}
                                color="brand"
                                display="inline-flex"
                                alignItems="center"
                                flexShrink={0}
                                cursor="pointer"
                                fontSize="13px"
                                fontWeight="semibold"
                              >
                                Download Sample CSV
                              </Link>{' '}
                              for message{' '}
                              <Text as="span" fontWeight="semibold">
                                &quot;Hello, %&#123;name&#125;. Your ticket
                                number is %&#123;ticket_number&#125;&quot;
                              </Text>
                            </FormHelperText>
                          </Flex>
                        </FormControl>
                      </Box>
                    )}
                  </Box>

                  <Flex
                    direction="row"
                    w="full"
                    align="center"
                    justify="flex-end"
                    pt="20px"
                  >
                    <Button
                      type="submit"
                      alt="Send message"
                      width={{ base: 'full', sm: 'auto' }}
                      isLoading={isSubmitting}
                      alignSelf="flex-end"
                      size="sm"
                      isDisabled={!filterEntityValue}
                    >
                      Send message
                    </Button>
                  </Flex>
                </VStack>
              </Box>
              <VStack align="flex-start" spacing={5}>
                <Box
                  borderRadius="15px"
                  background={modeBoxBg}
                  padding="20px 30px"
                  zIndex="1"
                  minW={{ base: 'none', md: 'fit-content' }}
                  position="sticky"
                  top="20px"
                  width="100%"
                  transition="top 0.3s"
                >
                  <Text textStyle="bodyBold" color="secondaryDark" pb="20px">
                    Preview push message
                  </Text>
                  <Grid
                    templateColumns={{ base: '1fr', '2xl': '1fr 1fr' }}
                    gap={4}
                  >
                    <GridItem>
                      <PreviewPassNotificationsApple
                        previewData={previewData}
                      />
                    </GridItem>
                    <GridItem>
                      <PreviewPassNotificationsAndroid
                        previewData={previewData}
                      />
                    </GridItem>
                  </Grid>
                </Box>
              </VStack>
            </Grid>
            <Stack
              spacing="10px"
              direction={{ base: 'column', sm: 'row' }}
              width={{ base: 'full', sm: 'auto' }}
            ></Stack>
          </form>

          <AlertDialogWrapper
            isOpen={isOpen}
            onClose={onClose}
            onConfirm={() => handleSend(formData)}
            title="Send Notifications"
            body={
              recipientType === '3'
                ? 'You are about to message all pass holders in the CSV file. Are you sure you wish to proceed?'
                : `You are about to message ${totalPasses} pass holder${
                    totalPasses > 1 ? 's' : ''
                  }. Are you sure you wish to proceed?`
            }
            buttonText="Send"
          />
        </>
      ) : (
        <Center>
          <Spinner
            width="52px"
            height="52px"
            thickness="4px"
            speed="0.65s"
            emptyColor="quinaryBackground"
            mt={4}
            mx="auto"
          />
        </Center>
      )}
    </>
  );
};

export default MessagesCreatePage;
