import { useState, useRef, useEffect, useContext } from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import mapboxgl from '!mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import PassTemplateContext from '../../store/client/PassTemplateContext';
import marker from '../../assets/images/marker.png';

mapboxgl.accessToken =
  process.env.REACT_APP_MAPBOX_API_KEY || window.env?.REACT_APP_MAPBOX_API_KEY;

const SearchableMap = (props) => {
  const {
    isEdit,
    radius,
    currentLocationIndex,
    editedFields,
    setEditedFields,
    recenterMap,
    setRecenterMap,
  } = props;

  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(0);
  const [lat, setLat] = useState(0);
  const [zoom, setZoom] = useState(1);
  const markers = [];
  const circles = [];
  const passTemplateCtx = useContext(PassTemplateContext);
  const locationsRef = useRef(passTemplateCtx.locations);
  const radiusRef = useRef(radius);
  const currentLocationIndexRef = useRef(currentLocationIndex);

  const addMarker = async (location) => {
    const el = document.createElement('div');
    el.className = 'my-custom-marker';
    el.style.backgroundImage = `url(${marker})`;
    el.style.backgroundSize = 'cover';
    el.style.width = '30px';
    el.style.height = '30px';

    const newMarker = new mapboxgl.Marker(el, { draggable: true })
      .setLngLat([location.longitude, location.latitude])
      .addTo(map.current);
    markers.push(newMarker);

    newMarker.on('drag', () => {
      const lngLat = newMarker.getLngLat();
      map.current
        .getSource(`circleSource-1`)
        .setData(
          createGeoJSONCircle(
            [lngLat.lng, lngLat.lat],
            radiusRef.current / 1000
          ).data
        );
    });

    newMarker.on('dragend', async () => {
      const lngLat = newMarker.getLngLat();
      const getAddress = async () => {
        return await fetch(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${lngLat.lng},${lngLat.lat}.json?access_token=${mapboxgl.accessToken}`
        )
          .then((res) => res.json())
          .then((data) => data.features[0].place_name);
      };
      try {
        const address = await getAddress();
        passTemplateCtx.updateLocations((prev) =>
          prev.map((loc, i) =>
            i === currentLocationIndexRef.current
              ? {
                  ...loc,
                  longitude: lngLat.lng,
                  latitude: lngLat.lat,
                  address: address,
                }
              : loc
          )
        );
        map.current
          .getSource(`circleSource-1`)
          .setData(
            createGeoJSONCircle(
              [lngLat.lng, lngLat.lat],
              radiusRef.current / 1000
            ).data
          );
        if (isEdit) {
          setEditedFields({
            ...editedFields,
            locations: true,
          });
        }
      } catch (error) {
        console.error('Failed to reverse geocode address', error);
      }
    });
  };

  const addCircle = (location) => {
    const circleSourceId = `circleSource-1`;
    const circleLayerId = `circleLayer-1`;

    map.current.addSource(
      circleSourceId,
      createGeoJSONCircle(
        [location.longitude, location.latitude],
        radiusRef.current / 1000
      )
    );

    map.current.addLayer({
      id: circleLayerId,
      type: 'fill',
      source: circleSourceId,
      layout: {},
      paint: {
        'fill-color': '#FF8679',
        'fill-opacity': 0.3,
      },
    });
    circles.push({
      sourceId: circleSourceId,
      layerId: circleLayerId,
    });
  };

  const createGeoJSONCircle = (center, radiusInKm, points) => {
    if (!points) points = 64;

    var coords = {
      latitude: center[1],
      longitude: center[0],
    };

    var km = radiusInKm;

    var ret = [];
    var distanceX = km / (111.32 * Math.cos((coords.latitude * Math.PI) / 180));
    var distanceY = km / 110.574;

    var theta, x, y;
    for (var i = 0; i < points; i++) {
      theta = (i / points) * (2 * Math.PI);
      x = distanceX * Math.cos(theta);
      y = distanceY * Math.sin(theta);

      ret.push([coords.longitude + x, coords.latitude + y]);
    }
    ret.push(ret[0]);

    return {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              coordinates: [ret],
            },
          },
        ],
      },
    };
  };

  useEffect(() => {
    // remove previous map if it exists
    if (map.current) {
      map.current.remove();
    }
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v12',
      center: [lng, lat],
      zoom: zoom,
      attributionControl: false,
    });

    // create geocoder and add to map
    const geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl: mapboxgl,
      marker: false,
    });

    map.current.addControl(geocoder);

    map.current.addControl(
      new mapboxgl.NavigationControl({
        showCompass: false,
      })
    );

    map.current.on('load', () => {
      if (!map.current.getSource('circleSource-1')) {
        if (passTemplateCtx.locations[currentLocationIndex]) {
          addMarker({
            longitude:
              passTemplateCtx.locations[currentLocationIndex].longitude,
            latitude: passTemplateCtx.locations[currentLocationIndex].latitude,
          });
          addCircle({
            longitude:
              passTemplateCtx.locations[currentLocationIndex].longitude,
            latitude: passTemplateCtx.locations[currentLocationIndex].latitude,
          });
        }
      }
    });

    // each time a result is found, clear markers and circles and add new ones
    geocoder.on('result', function (e) {
      const { geometry, place_name } = e.result;
      if (markers.length > 0) {
        markers.forEach((marker) => marker.remove());
      }
      if (circles.length > 0) {
        if (map.current.getSource(`circleSource-1`)) {
          map.current.removeLayer(`circleLayer-1`);
          map.current.removeSource(`circleSource-1`);
        }
      }
      map.current.setCenter(geometry.coordinates);
      map.current.setZoom(16);

      addMarker({
        longitude: geometry.coordinates[0],
        latitude: geometry.coordinates[1],
      });
      addCircle({
        longitude: geometry.coordinates[0],
        latitude: geometry.coordinates[1],
      });
      if (isEdit) {
        setEditedFields({
          ...editedFields,
          locations: true,
        });
      }
      passTemplateCtx.updateLocations((prev) =>
        prev.map((loc, i) =>
          i === currentLocationIndexRef.current
            ? {
                ...loc,
                address: place_name,
                latitude: geometry.coordinates[1],
                longitude: geometry.coordinates[0],
              }
            : loc
        )
      );
      geocoder.clear();
    });

    map.current.on('move', () => {
      setLng(map.current.getCenter().lng.toFixed(4));
      setLat(map.current.getCenter().lat.toFixed(4));
      setZoom(map.current.getZoom().toFixed(2));
    });
  }, [currentLocationIndex, passTemplateCtx.locations.length]);

  useEffect(() => {
    if (!passTemplateCtx.locations[currentLocationIndex]) return;
    if (map.current.getSource(`circleSource-1`)) {
      map.current
        .getSource(`circleSource-1`)
        .setData(
          createGeoJSONCircle(
            [
              passTemplateCtx.locations[currentLocationIndex].longitude,
              passTemplateCtx.locations[currentLocationIndex].latitude,
            ],
            passTemplateCtx.locations[currentLocationIndex].radius / 1000
          ).data
        );
    }
  }, [currentLocationIndex, radius]);

  useEffect(() => {
    radiusRef.current = radius;
  }, [radius]);

  useEffect(() => {
    currentLocationIndexRef.current = currentLocationIndex;
  }, [currentLocationIndex]);

  useEffect(() => {
    locationsRef.current = passTemplateCtx.locations;
  }, [passTemplateCtx.locations]);

  useEffect(() => {
    if (!currentLocationIndex && currentLocationIndex !== 0) return;
    if (!passTemplateCtx.locations.length) {
      map.current.setCenter([-0.1, 51.5072]);
      map.current.setZoom(9);
      return;
    }
    const location = passTemplateCtx.locations[currentLocationIndex];
    if (location) {
      if (!location.latitude || !location.longitude) {
        map.current.setCenter([-0.1, 51.5072]);
        map.current.setZoom(9);
        return;
      }
      map.current.setCenter([location.longitude, location.latitude]);
      map.current.setZoom(16);
    }
  }, [currentLocationIndex, passTemplateCtx.locations]);

  useEffect(() => {
    if (!recenterMap) return;
    const location = passTemplateCtx.locations[currentLocationIndex];
    map.current.setCenter([location.longitude, location.latitude]);
    map.current.setZoom(16);
    setRecenterMap(false);
  }, [recenterMap]);

  return (
    <div>
      <div ref={mapContainer} className="map-container" />
    </div>
  );
};

export default SearchableMap;
