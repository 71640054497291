import {
  Box,
  VStack,
  Heading,
  Text,
  Button,
  Stack,
  Square,
  Divider,
  Center,
  useMediaQuery,
  Skeleton,
  useColorModeValue,
  Flex,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { CustomEventTicketIcon } from '../../../theme/icons/CustomEventTicketIcon';
import moment from 'moment';

const DashboardEvents = ({ isLoading, eventsData }) => {
  const [isSmallerThan1475] = useMediaQuery('(max-width: 92em)');
  const [isSmallerThan770] = useMediaQuery('(max-width: 770px)');
  const [isSmallerThan600] = useMediaQuery('(max-width: 600px)');

  const [isLargerThanXL] = useMediaQuery('(min-width: 80em)');
  const rangeMinimize = isLargerThanXL && isSmallerThan1475;
  const modeSkeletonStartColor = useColorModeValue('#d8d8d8', '#29323A');
  const modeSkeletonEndColor = useColorModeValue('#EFEFEF', '#515d66');
  const modeSquareBg = useColorModeValue('gradientDark', 'primaryDark');
  const { totalEvents, nextEvent, passesIssued } = eventsData;

  return (
    <Box
      w="full"
      bg="linear-gradient(81.62deg, #FF8477 2.25%, #EC6759 79.87%)"
      borderRadius="15px"
      boxShadow="0px 3.5px 5.5px rgba(0, 0, 0, 0.02)"
      px={7}
      pt={5}
      color="white"
    >
      {isSmallerThan600 && (
        <Skeleton
          isLoaded={!isLoading}
          startColor={modeSkeletonStartColor}
          endColor={modeSkeletonEndColor}
        >
          <Heading size="xl" pl={4} pb={6} pt={3}>
            Events
          </Heading>
        </Skeleton>
      )}
      <VStack align="start" h={{ base: 'auto', md: '180px' }} gap="14px" mb={4}>
        {!isSmallerThan600 && (
          <Skeleton
            isLoaded={!isLoading}
            startColor={modeSkeletonStartColor}
            endColor={modeSkeletonEndColor}
          >
            <Heading size="xl" pl={4}>
              Events
            </Heading>
          </Skeleton>
        )}
        <Stack
          direction="row"
          gap={!isSmallerThan600 ? 1 : 3}
          justify="space-around"
          w="full"
        >
          <>
            <Stack
              direction="row"
              h="full"
              gap={isSmallerThan600 ? 3 : 4}
              justify="space-between"
              align="center"
            >
              <Skeleton
                isLoaded={!isLoading}
                borderRadius="15px"
                startColor={modeSkeletonStartColor}
                endColor={modeSkeletonEndColor}
              >
                <Square
                  size={isSmallerThan600 ? '50px' : '82px'}
                  bg={modeSquareBg}
                  borderRadius="15px"
                  boxShadow="0px 3.5px 5.5px rgba(0, 0, 0, 0.02)"
                >
                  <CustomEventTicketIcon
                    w={isSmallerThan600 ? '30px' : '52px'}
                    h="auto"
                  />
                </Square>
              </Skeleton>
              <Skeleton
                isLoaded={!isLoading}
                borderRadius="15px"
                startColor={modeSkeletonStartColor}
                endColor={modeSkeletonEndColor}
              >
                <Flex
                  direction="column"
                  gap={0}
                  align="center"
                  lineHeight="initial"
                  justifyContent="center"
                >
                  <Heading>{totalEvents}</Heading>
                  <Text mt={0} fontSize="14px">
                    Total
                  </Text>
                </Flex>
              </Skeleton>
            </Stack>
            <Center>
              <Divider
                w="1px"
                bg="white"
                h="100%"
                ml={rangeMinimize ? 0 : 3}
                mr={rangeMinimize ? 0 : 3}
                mb={isSmallerThan770 ? 3 : 0}
              />
            </Center>
          </>
          <Skeleton
            isLoaded={!isLoading}
            startColor={modeSkeletonStartColor}
            endColor={modeSkeletonEndColor}
            borderRadius="15px"
          >
            <Stack align={{ base: 'center' }}>
              <Flex direction="column">
                {nextEvent ? (
                  <>
                    <Skeleton
                      isLoaded={!isLoading}
                      startColor={modeSkeletonStartColor}
                      endColor={modeSkeletonEndColor}
                    >
                      <Text>Next: {nextEvent?.attributes.name}</Text>
                      <Text>
                        Date:{' '}
                        {moment(nextEvent?.attributes.startTime).format(
                          'DD/MM/YY'
                        )}
                      </Text>
                      <Text>Issued passes: {passesIssued}</Text>
                    </Skeleton>
                  </>
                ) : (
                  <Skeleton
                    isLoaded={!isLoading}
                    startColor={modeSkeletonStartColor}
                    endColor={modeSkeletonEndColor}
                  >
                    <Text>No upcoming events</Text>
                  </Skeleton>
                )}
                <Button
                  as={Link}
                  to="/events"
                  size="sm"
                  variant="white"
                  fontSize="14px"
                  mt={4}
                  w={40}
                  mb={isSmallerThan770 ? 3 : 0}
                >
                  All Events
                </Button>
              </Flex>
            </Stack>
          </Skeleton>
        </Stack>
      </VStack>
    </Box>
  );
};

export default DashboardEvents;
