import {
  Box,
  Flex,
  FormControl,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  Select,
  FormHelperText,
  useColorModeValue,
  FormErrorMessage,
  VStack,
} from '@chakra-ui/react';
import CustomDatePicker from '../../components/common/CustomDatePicker/CustomDatePicker';
import CustomTimePicker from '../../components/common/CustomTimePicker/CustomTimePicker';

import { useForm } from 'react-hook-form';

import { useState, useContext, useEffect } from 'react';
import { useMemo } from 'react';
import { timezoneList } from '../../utils/timezones';
import PassTemplateContext from '../../store/client/PassTemplateContext';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';

const TemplateScheduleUpdateModal = ({
  editedFields,
  setEditedFields,
  onClose,
  isOpen,
  setScheduledDateTime,
}) => {
  const passTemplateCtx = useContext(PassTemplateContext);
  const modeBoxBg = useColorModeValue('white', 'secondaryDark');
  const modeTextBg = useColorModeValue('white', 'primaryDark06');
  const modeFormControlBg = useColorModeValue('#EFEFEF', 'primaryDark03');
  const [scheduledDate, setScheduledDate] = useState(null);
  const [scheduledTime, setScheduledTime] = useState('');
  const modeLabelBg = useColorModeValue('white', 'primaryDark03');
  const timezones = useMemo(timezoneList, []);

  const handleConfirm = async () => {
    const isValid = await trigger();
    if (isValid) {
      const data = getValues();
      const scheduledAt = buildScheduledAt(data);
      if (scheduledAt) {
        setScheduledDateTime(scheduledAt);
        setEditedFields({
          ...editedFields,
          scheduled_at: scheduledAt,
        });
        onClose();
      }
    }
  };

  const buildScheduledAt = (data) => {
    if (!data.scheduledDate || !data.scheduledTime || !data.timezone) {
      return null;
    }

    const date = moment(data.scheduledDate).format('YYYY-MM-DD');
    const time = moment(data.scheduledTime, 'hh:mm A').format('HH:mm:ss');
    const dateTime = moment.tz(
      `${date} ${time}`,
      'YYYY-MM-DD HH:mm:ss',
      data.timezone
    );
    return dateTime.toISOString();
  };

  const validationSchema = Yup.object().shape({
    scheduledDate: Yup.date()
      .nullable()
      .required('Scheduled date is required')
      .test(
        'valid-schedule',
        'Selected date/time must be between 1 hour and 365 days from now',
        function (value) {
          console.log('value', value);
          console.log('this.parent', this.parent);
          if (!value) return false;
          const scheduledAt = buildScheduledAt(this.parent);

          if (!scheduledAt) return true;

          const now = new Date();
          const minTime = new Date(now.getTime() + 60 * 60 * 1000); // 1 hour from now
          const maxTime = new Date(now.getTime() + 365 * 24 * 60 * 60 * 1000); // 365 days from now

          const scheduledDate = new Date(scheduledAt);
          return scheduledDate >= minTime && scheduledDate <= maxTime;
        }
      ),
    scheduledTime: Yup.string()
      .nullable()
      .required('Scheduled time is required')
      .matches(
        /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]\s?(AM|PM)$/i,
        'Scheduled time must be in HH:MM AM/PM format'
      ),
    timezone: Yup.string().required('Timezone is required'),
  });

  const {
    register,
    reset,
    trigger,
    handleSubmit,
    control,
    watch,
    formState: { isSubmitting, errors },
    setValue,
    getValues,
  } = useForm({
    resolver: yupResolver(validationSchema),
    shouldUnregister: true,
    defaultValues: {
      scheduledDate: null,
      scheduledTime: '',
      timezone: moment.tz.guess(),
    },
  });

  useEffect(() => {
    setValue('scheduledDate', scheduledDate);
    if (scheduledDate) {
      trigger('scheduledDate');
    }
  }, [scheduledDate, setValue]);

  useEffect(() => {
    setValue('scheduledTime', scheduledTime);
    if (scheduledTime) {
      trigger('scheduledTime');
    }
  }, [scheduledTime, setValue]);

  return (
    <>
      <Modal onClose={onClose} isOpen={isOpen} size="xl">
        <ModalOverlay />
        <ModalContent bg={modeBoxBg} p="20px">
          <ModalHeader
            bg={modeTextBg}
            pb="20px"
            borderRadius="15px 15px 0 0"
          ></ModalHeader>
          <ModalBody bg={modeLabelBg}>
            <VStack spacing="10px" textAlign="center">
              <Text fontSize="18px" textStyle="bodyBold" color="secondaryDark">
                To schedule the update, please select a date and time.
              </Text>
              {/* Scheduled At */}

              <Box>
                <FormControl
                  isInvalid={errors.scheduledDate?.message?.includes(
                    'Selected date/time must be between'
                  )}
                >
                  <Box
                    w="full"
                    p="19px"
                    bg={modeFormControlBg}
                    borderRadius="15px"
                  >
                    <Flex
                      direction={{
                        base: 'column',
                        md: 'row',
                      }}
                      justifyContent="space-between"
                      gap={3}
                      mb={3}
                    >
                      <Box w="full">
                        <FormControl
                          isInvalid={
                            errors.scheduledDate &&
                            !errors.scheduledDate.message?.includes(
                              'Selected date/time must be between'
                            )
                          }
                        >
                          <CustomDatePicker
                            setDate={setScheduledDate}
                            dateValue={scheduledDate}
                            startDate
                            minBookingDate={new Date()}
                          />
                          {errors.scheduledDate &&
                            !errors.scheduledDate.message?.includes(
                              'Selected date/time must be between'
                            ) && (
                              <FormErrorMessage mt={2}>
                                {errors.scheduledDate.message}
                              </FormErrorMessage>
                            )}
                        </FormControl>
                      </Box>
                      <Box w="full">
                        <FormControl isInvalid={errors.scheduledTime}>
                          <CustomTimePicker
                            timeValue={scheduledTime}
                            setTimeValue={setScheduledTime}
                            startTime
                          />
                          {errors.scheduledTime && (
                            <FormErrorMessage mt={2}>
                              {errors.scheduledTime.message}
                            </FormErrorMessage>
                          )}
                        </FormControl>
                      </Box>
                    </Flex>
                    <Box>
                      <FormControl isInvalid={errors.timezone}>
                        <Select
                          {...register('timezone')}
                          variant="filledForDarkBg"
                        >
                          {timezones.map((timezone, index) => {
                            return (
                              <option key={index} value={timezone.name}>
                                {timezone.key}
                              </option>
                            );
                          })}
                        </Select>
                        {errors.timezone && (
                          <FormErrorMessage mt={2}>
                            {errors.timezone.message}
                          </FormErrorMessage>
                        )}
                      </FormControl>
                    </Box>
                  </Box>
                  {errors.scheduledDate?.message?.includes(
                    'Selected date/time must be between'
                  ) && (
                    <FormErrorMessage mt={2}>
                      {errors.scheduledDate.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
                <FormControl>
                  <FormHelperText mt={2}>
                    You can schedule template updates between 1 hour from now
                    and up to 365 days in advance. When the template is updated,
                    the changes will be reflected in all passes that use this
                    template.
                  </FormHelperText>
                </FormControl>
              </Box>
            </VStack>
          </ModalBody>
          <ModalFooter bg={modeTextBg} borderRadius="0 0 15px 15px">
            <Stack
              mt="15px"
              spacing="10px"
              direction={{ base: 'column', sm: 'row' }}
              width={{ base: 'full', sm: 'auto' }}
            >
              <Button
                onClick={onClose}
                alt="Cancel"
                variant="secondary"
                width={{ base: 'full', sm: 'auto' }}
              >
                Back
              </Button>
              <Button
                alt="confirm"
                isLoading={isSubmitting}
                width={{ base: 'full', sm: 'auto' }}
                onClick={handleConfirm}
              >
                Confirm
              </Button>
            </Stack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default TemplateScheduleUpdateModal;
