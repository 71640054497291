import {
  useColorModeValue,
  Box,
  Text,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Button,
  Hide,
  Show,
  useToast,
  useDisclosure,
  FormHelperText,
  FormErrorMessage,
} from '@chakra-ui/react';
import { useState, useEffect, useContext } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { formatOptionLabelFilterPassTemplate } from '../../../components/common/CustomReactSelect';
import { CustomReactSelect } from '../../../components/common/CustomReactSelect';
import { HttpContext } from '../../../context/HttpContext';
import { MeContext } from '../../../context/MeContext';
import { CustomCsvDownloadIcon } from '../../../theme/icons/CustomCsvDownloadIcon';

import CustomToast from '../../../common/CustomToast';
import { getErrors } from '../../../utils/ajax';

const PassesCreateCSVForm = () => {
  const modeBoxBg = useColorModeValue('gradient', 'secondaryDark');
  const modeFormControlBg = useColorModeValue('white', '');
  const modeLabelBg = useColorModeValue('#EFEFEF', 'primaryDark03');
  const modeTextBg = useColorModeValue('quaternaryBackground', 'primaryDark06');
  const modeFormBoxBg = useColorModeValue('gradient', 'secondaryDark');
  const modeBorderColor = useColorModeValue('primaryBackground', 'primaryDark');
  const [csvStructure, setCsvStructure] = useState(null);
  const { authAxios } = useContext(HttpContext);
  const toast = useToast();
  const meContext = useContext(MeContext);
  const navigate = useNavigate();

  const { filterEntity } = meContext.state;

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [passTemplatesList, setPassTemplatesList] = useState([]);

  const validationSchema = Yup.object().shape({
    batchName: Yup.string(),
    passTemplate: Yup.object().required('Pass template is required'),
    csvFile: Yup.mixed()
      .required('CSV file is required')
      .test('fileType', 'Only .csv files are accepted', (value) => {
        return value?.[0]?.type === 'text/csv';
      }),
  });

  const {
    register,
    handleSubmit,
    watch,
    control,
    reset,
    setValue,
    getValues,
    formState: { errors, isSubmitting, isValid },
  } = useForm({
    resolver: yupResolver(validationSchema),
    shouldUnregister: true,
    defaultValues: {
      batchName: '',
      passTemplate: null,
      csvFile: null,
    },
  });

  const watchCsvFile = watch('csvFile');
  const watchPassTemplate = watch('passTemplate');

  // fetch all PassTemplates for select PassTemplates dropdown
  useEffect(() => {
    const getData = async () => {
      try {
        let response;
        if (filterEntity) {
          response = await authAxios.get(
            `entities/${filterEntity.uuid}/passes/templates?fields=name`
          );
        } else {
          response = await authAxios.get(`passes/templates?fields=name`);
        }
        const data = response.data?.data;
        let templates = [];
        if (data.length === 0) {
          setPassTemplatesList([]);
          return;
        }
        data.forEach((item) => {
          templates.push({
            label: item.name,
            value: item.uuid,
          });
        });
        setPassTemplatesList(templates);
      } catch (error) {
        console.log(error);
        const { message, code } = getErrors(error);
        code !== 401 &&
          toast({
            render: (props) => (
              <CustomToast
                status="error"
                title={message ? message : `Something went wrong`}
                description={!message && 'Please try again later'}
                onClose={props.onClose}
              />
            ),
          });
      }
    };
    getData();
  }, [authAxios, setPassTemplatesList, toast, filterEntity]);

  const prepareSampleCsv = async () => {
    const selectedTemplate = getValues('passTemplate');
    if (!selectedTemplate) return;

    try {
      // Fetch template details including fields
      const response = await authAxios.get(
        `passes/templates/${selectedTemplate.value}`
      );
      const templateData = response.data?.data;

      // Define default columns
      const defaultColumns = [
        'ext_id',
        'group_tag',
        'nfc_enabled',
        'nfc_source',
        'nfc_custom_value',
        'barcode_qr_value',
        'barcode_qr_display_text',
        'expires_at',
      ];

      const dynamicFields = [
        // Header fields
        templateData.headerOneId && `passfield_${templateData.headerOneId}`,
        templateData.headerTwoId && `passfield_${templateData.headerTwoId}`,
        templateData.headerThreeId && `passfield_${templateData.headerThreeId}`,
        // Primary fields
        templateData.primaryOneId && `passfield_${templateData.primaryOneId}`,
        templateData.primaryTwoId && `passfield_${templateData.primaryTwoId}`,
        // Secondary fields
        templateData.secOneId && `passfield_${templateData.secOneId}`,
        templateData.secTwoId && `passfield_${templateData.secTwoId}`,
        templateData.secThreeId && `passfield_${templateData.secThreeId}`,
        // Auxiliary fields
        templateData.auxOneId && `passfield_${templateData.auxOneId}`,
        templateData.auxTwoId && `passfield_${templateData.auxTwoId}`,
        templateData.auxThreeId && `passfield_${templateData.auxThreeId}`,
        // Back fields
        templateData.backOneId && `passfield_${templateData.backOneId}`,
        templateData.backTwoId && `passfield_${templateData.backTwoId}`,
        templateData.backThreeId && `passfield_${templateData.backThreeId}`,
        templateData.backFourId && `passfield_${templateData.backFourId}`,
        templateData.backFiveId && `passfield_${templateData.backFiveId}`,
      ].filter(Boolean);

      // Combine all columns
      const allColumns = [...defaultColumns, ...dynamicFields];

      // Store the structure
      setCsvStructure({
        columns: allColumns,
        templateName: selectedTemplate.label,
      });
    } catch (error) {
      console.error(error);
      const { message, code } = getErrors(error);
      code !== 401 &&
        toast({
          render: (props) => (
            <CustomToast
              status="error"
              title={message ? message : `Failed to prepare CSV structure`}
              description={!message && 'Please try again later'}
              onClose={props.onClose}
            />
          ),
        });
    }
  };

  const downloadSampleCsv = () => {
    if (!csvStructure) return;

    const sampleDataRow = csvStructure.columns.map((column) => {
      switch (column) {
        case 'ext_id':
          return 'PASS123';
        case 'group_tag':
          return 'VIP Campaign';
        case 'nfc_enabled':
          return 'true';
        case 'nfc_source':
          return 'custom';
        case 'nfc_custom_value':
          return 'PROMO198354';
        case 'barcode_qr_value':
          return '';
        case 'barcode_qr_display_text':
          return '';
        case 'expires_at':
          return '2025-01-01T10:30:00.000Z';
        default:
          // For passfield columns, provide a sample value
          return column.startsWith('passfield_') ? 'Sample Value' : '';
      }
    });

    const csvContent = [
      csvStructure.columns.join(','), // Header row
      sampleDataRow.join(','), // Sample data row
      Array(csvStructure.columns.length).fill('').join(','), // Empty example row
    ].join('\n');

    // Create and trigger download
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', `${csvStructure.templateName}_template.csv`);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  const onSubmit = async (data) => {
    try {
      const formData = new FormData();
      if (data.batchName) {
        formData.append('name', data.batchName);
      }
      formData.append('passTemplate', data.passTemplate.value);
      formData.append('csv', data.csvFile[0]);

      const response = await authAxios.post('api/v1/passes/batch/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      toast({
        render: (props) => (
          <CustomToast
            status="success"
            title="Batch creation started"
            description="Your passes are being created in the background"
            onClose={props.onClose}
          />
        ),
      });

      // Redirect to batches page
      navigate('/passes/batches');
    } catch (error) {
      const message = error?.response?.data?.errors?.[0];
      toast({
        render: (props) => (
          <CustomToast
            status="error"
            title="Failed to create batch"
            description={message || 'Please try again later'}
            onClose={props.onClose}
          />
        ),
      });
    }
  };

  return (
    <>
      <Box w="full" mx="auto" p={4}>
        <Box className="primaryBoxShadow" bg={modeLabelBg} borderRadius="15px">
          <Text
            fontSize="18px"
            textStyle="headingFamilyMedium"
            p="14px 14px 14px 24px"
            bg={modeTextBg}
            borderRadius="15px 15px 0 0"
          >
            Create passes via CSV
          </Text>
          <Divider borderColor={modeBorderColor} />

          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Flex direction={{ base: 'column', xl: 'row' }} p={8} gap={8}>
              {/* Left Column - Basic Info */}
              <Box flex="1">
                {/* Batch Name */}
                <FormControl mb={6} isInvalid={!!errors.batchName}>
                  <FormLabel mb={3}>Batch Name</FormLabel>
                  <Input
                    variant="filledForDarkBg"
                    id="batchName"
                    type="text"
                    autoComplete="off"
                    placeholder="Name your batch to help identify it"
                    {...register('batchName')}
                  />
                  {errors.batchName && (
                    <FormErrorMessage>
                      {errors.batchName.message}
                    </FormErrorMessage>
                  )}
                </FormControl>

                {/* Pass Template */}
                <FormControl
                  isRequired
                  mb={6}
                  isInvalid={!!errors.passTemplate}
                >
                  <FormLabel mb={3}>Pass Template</FormLabel>
                  <CustomReactSelect
                    options={passTemplatesList}
                    placeholder={'Select pass template'}
                    noOptionsMessage={() => 'No pass templates found'}
                    menuPlacement="auto"
                    formatOptionLabel={formatOptionLabelFilterPassTemplate}
                    onChange={(selected) => {
                      setValue('passTemplate', selected, {
                        shouldValidate: true,
                      });
                      prepareSampleCsv();
                    }}
                    value={watch('passTemplate')}
                    id="passTemplate"
                    passTemplate
                  />
                  {errors.passTemplate && (
                    <FormErrorMessage mt={2}>
                      {errors.passTemplate.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </Box>

              {/* Right Column - CSV Upload */}
              <Box
                flex="1"
                bg={modeFormBoxBg}
                p={6}
                borderRadius="md"
                border="1px dashed"
                borderColor={modeBorderColor}
              >
                <FormControl isRequired isInvalid={!!errors.csvFile}>
                  <FormLabel fontSize="lg" mb={4}>
                    CSV File Upload
                  </FormLabel>
                  <Text mb={4} fontSize="sm" color="gray.500">
                    Upload a CSV file containing customer data for pass
                    creation.
                  </Text>

                  <Button
                    as="label"
                    htmlFor="csv-upload"
                    cursor="pointer"
                    variant="outline"
                    size="md"
                    width="full"
                    mb={2}
                    bg="quinaryBackground"
                    borderColor={errors.csvFile ? 'error' : 'inherit'}
                    borderWidth={errors.csvFile ? '2px' : '1px'}
                    color={errors.csvFile ? 'red.500' : 'inherit'}
                  >
                    {watchCsvFile && watchCsvFile[0]
                      ? watchCsvFile[0].name
                      : 'Choose File'}
                    <input
                      id="csv-upload"
                      type="file"
                      accept=".csv"
                      style={{ display: 'none' }}
                      {...register('csvFile')}
                    />
                  </Button>

                  {errors.csvFile && (
                    <FormErrorMessage mb={2} mt={0}>
                      {errors.csvFile.message}
                    </FormErrorMessage>
                  )}

                  <FormHelperText>Max file size: 10MB</FormHelperText>

                  {watchPassTemplate && (
                    <Button
                      variant="link"
                      colorScheme="blue"
                      size="sm"
                      mt={4}
                      leftIcon={<CustomCsvDownloadIcon boxSize={6} />}
                      onClick={downloadSampleCsv}
                      whiteSpace="normal"
                      textAlign="left"
                    >
                      Download Sample CSV Structure for{' '}
                      {watchPassTemplate.label}
                    </Button>
                  )}
                </FormControl>
              </Box>
            </Flex>

            {/* Submit Button */}
            <Flex
              justifyContent="flex-end"
              p={4}
              borderTop="1px solid"
              borderColor={modeBorderColor}
              bg={modeTextBg}
              borderRadius="0 0 15px 15px"
            >
              <Button
                size="md"
                variant="secondary"
                as={RouterLink}
                to="/passes/batches"
                type="button"
                mr={4}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                width={{ base: 'full', sm: 'auto' }}
                isLoading={isSubmitting}
                colorScheme="blue"
              >
                Create Passes
              </Button>
            </Flex>
          </form>
        </Box>
      </Box>
    </>
  );
};

export default PassesCreateCSVForm;
