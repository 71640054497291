import appsignal from '../appsignal';
import { useToast } from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import CustomToast from '../common/CustomToast';
import { HttpContext } from '../context/HttpContext';
import { MeContext } from '../context/MeContext';

function UseMe({ children }) {
  const { authAxios } = useContext(HttpContext);
  const { updateState } = useContext(MeContext);
  const toast = useToast();
  const [getDataState, setGetDataState] = useState(true);

  useEffect(() => {
    const getData = async () => {
      setGetDataState(true);
      try {
        const response = await authAxios.get(`accounts/me`);
        const account = response?.data?.account;
        const organisationStatus = response?.data?.organisation_status;
        const trialExpiry = response?.data?.trial_expiry;
        const watermarkEnabled = response?.data?.watermark_enabled;
        const entities = response?.data?.entities;
        const passTemplates = response?.data?.pass_templates;
        const invitations = response?.data?.invitations;
        const invitationLimit = account?.invitation_limit;
        const invitedAccount = account['invited_account?'];
        const batchNotificationsEnabled =
          response?.data?.batch_notifications_enabled;
        const scheduledPassTemplateUpdatesEnabled =
          response?.data?.scheduled_pass_template_updates_enabled;
        const eventsEnabled = response?.data?.events_enabled;
        const filterEntity = response?.data?.filter_entity;
        updateState({
          account,
          organisationStatus,
          trialExpiry,
          watermarkEnabled,
          entities,
          passTemplates,
          invitations,
          invitationLimit,
          invitedAccount,
          batchNotificationsEnabled,
          eventsEnabled,
          scheduledPassTemplateUpdatesEnabled,
          filterEntity,
          status: 'resolved',
          error: null,
        });
        setGetDataState(false);
      } catch (onError) {
        appsignal.sendError(onError);
        const errors = onError.response.data.errors;
        if (errors) {
          errors.forEach((error) => {
            toast({
              render: (props) => (
                <CustomToast
                  status="error"
                  title={error}
                  description=""
                  onClose={props.onClose}
                />
              ),
            });
          });
        }
        setGetDataState(false);
        updateState({
          account: null,
          organisationStatus: null,
          trialExpiry: null,
          watermarkEnabled: null,
          entities: null,
          invitations: null,
          invitationLimit: null,
          batchNotificationsEnabled: null,
          eventsEnabled: null,
          scheduledPassTemplateUpdatesEnabled: null,
          filterEntity: null,
          status: 'rejected',
          error: 'Something went wrong',
        });
      }
    };

    getData();
  }, [authAxios]);

  if (!getDataState) return <>{children}</>;
  return null;
}

export default UseMe;
