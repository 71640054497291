import React, { useEffect } from 'react';
import { useTable, usePagination } from 'react-table';
import TableBox from '../../common/TableBox';
import ReactTablePagination from '../../../common/ReactTablePagination';
import EmptyState from '../../../common/EmptyState';
import MessageStatus from './MessageStatus';
import MessageBatchMoreInfo from './MessageBatchMoreInfo';
import { Link } from 'react-router-dom';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Spinner,
  Box,
  Text,
  Heading,
  Flex,
  Stack,
  Button,
  Badge,
  useMediaQuery,
} from '@chakra-ui/react';
import { CustomCsvDownloadIcon } from '../../../theme/icons/CustomCsvDownloadIcon';

const MessagingHistoryTable = ({
  columns,
  heading,
  data,
  fetchData,
  isLoading,
  pageCount,
  total,
  cancel,
  tableType,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount: controlledPageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setHiddenColumns,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      manualPagination: true,
      pageCount,
      initialState: { pageIndex: 0 },
    },
    usePagination
  );

  const [isLargerThan2XL] = useMediaQuery('(min-width: 96em)');
  const [isLargerThanXL] = useMediaQuery('(min-width: 80em)');
  const [isLargerThanMD] = useMediaQuery('(min-width: 48em)');
  const [isLargerThanSM] = useMediaQuery('(min-width: 30em)');
  const [isLargerThanS] = useMediaQuery('(min-width: 18em)');
  const [isLargerThanXS] = useMediaQuery('(min-width: 10em)');

  useEffect(() => {
    fetchData({ pageIndex, pageSize });
  }, [fetchData, pageIndex, pageSize]);

  useEffect(() => {
    if (isLargerThan2XL) {
      setHiddenColumns([]);
    } else if (isLargerThanXL) {
      if (tableType === 'scheduled') {
        setHiddenColumns([]);
      } else {
        setHiddenColumns(['content']);
      }
    } else if (isLargerThanMD) {
      setHiddenColumns(['content', 'completedAt']);
    } else if (isLargerThanSM) {
      setHiddenColumns([
        'content',
        'completedAt',
        'recipients',
        'status',
        'cancelAction',
      ]);
    } else if (isLargerThanS) {
      setHiddenColumns([
        'content',
        'completedAt',
        'recipients',
        'errors',
        'messageType',
        'status',
        'cancelAction',
      ]);
    } else {
      setHiddenColumns([
        'content',
        'completedAt',
        'recipients',
        'errors',
        'messageType',
        'status',
        'cancelAction',
      ]);
    }
  }, [
    isLargerThan2XL,
    isLargerThanXL,
    isLargerThanMD,
    isLargerThanSM,
    isLargerThanS,
    isLargerThanXS,
    setHiddenColumns,
    tableType,
  ]);

  if (isLoading) {
    return (
      <Box textAlign="center">
        <Spinner />
      </Box>
    );
  }

  if (data.length === 0 && !isLoading) {
    return (
      <EmptyState
        heading={
          tableType === 'scheduled'
            ? 'No Scheduled Messages'
            : 'No Messaging History'
        }
        text={
          tableType === 'scheduled'
            ? 'Click on the button below to start scheduling your messages.'
            : 'Click on the button below to start sending your messages.'
        }
      >
        <Button alt="New Message" size="sm" as={Link} to="/messaging/create">
          New Message
        </Button>
      </EmptyState>
    );
  }

  return (
    <>
      <TableBox>
        <Heading size="lg" mb="16px">
          {heading}{' '}
          <Text
            as="span"
            textStyle="headingFamilyMedium"
            fontSize="18px"
            fontWeight="500"
          >
            (
            <Box as="span" color="brand">
              {total}
            </Box>
            )
          </Text>
        </Heading>
        <Table {...getTableProps()}>
          <Thead>
            {headerGroups.map((headerGroup) => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <Th {...column.getHeaderProps()} style={column.style}>
                    {column.render('Header')}
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <Tr
                  {...row.getRowProps()}
                  _hover={{
                    cursor: 'default',
                  }}
                >
                  {row.cells.map((cell) => {
                    return (
                      <Td {...cell.getCellProps()} style={cell.column.style}>
                        {cell.column.id === 'status' ? (
                          <MessageStatus status={cell.value} type="batch" />
                        ) : cell.column.id === 'cancelAction' ? (
                          <Button
                            variant="secondary"
                            size="sm"
                            px="19px"
                            onClick={() => {
                              cancel(
                                cell.row.original.id,
                                cell.row.original.messageType
                              );
                              fetchData({ pageSize: 10, pageIndex: 0 });
                            }}
                          >
                            Cancel
                          </Button>
                        ) : cell.column.id === 'errors' ? (
                          cell.row.original.messageType === 'Wallet' &&
                          cell.row.original.errorsCsv ? (
                            <Link to={cell.row.original.errorsCsv}>
                              <CustomCsvDownloadIcon
                                color="brand"
                                boxSize={7}
                              />
                            </Link>
                          ) : cell.value > 0 ? (
                            <Badge
                              variant="outline"
                              colorScheme="red"
                              fontWeight="bold"
                              cursor="pointer"
                              onClick={() => {
                                cell.column.setTabIndex?.(1);
                              }}
                            >
                              {cell.value}
                            </Badge>
                          ) : null
                        ) : cell.column.id === 'moreInfo' ? (
                          <MessageBatchMoreInfo cell={cell} cancel={cancel} />
                        ) : cell.column.id === 'name' &&
                          isLargerThanXS &&
                          !isLargerThanMD ? (
                          <Flex alignItems="center">
                            <MessageStatus
                              status={cell.row.original.status}
                              type="batch"
                              showLabel={false}
                            />
                            <Text ml={3}>{cell.value}</Text>
                          </Flex>
                        ) : (
                          cell.render('Cell')
                        )}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        {data.length > 0 && (
          <Flex
            mt={6}
            flexDirection={{ base: 'column-reverse', '2xl': 'row' }}
            justifyContent={{ base: 'none', '2xl': 'space-between' }}
          >
            <Stack
              spacing="18px"
              direction={{ base: 'column', sm: 'row' }}
              shouldWrapChildren="true"
            ></Stack>

            <ReactTablePagination
              canPreviousPage={canPreviousPage}
              canNextPage={canNextPage}
              pageOptions={pageOptions}
              pageCount={pageCount}
              gotoPage={gotoPage}
              nextPage={nextPage}
              previousPage={previousPage}
              setPageSize={setPageSize}
              pageIndex={pageIndex}
              pageSize={pageSize}
            />
          </Flex>
        )}
      </TableBox>
    </>
  );
};

export default MessagingHistoryTable;
