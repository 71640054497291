import { Box, Text, Tooltip, useColorModeValue } from '@chakra-ui/react';
import { CustomCalendarIcon } from '../../../theme/icons/CustomCalendarIcon';

function ScheduledUpdatesThumbnail({ previewData }) {
  const bgTooltip = useColorModeValue('secondaryDark', '#dddddd');
  const textTooltip = useColorModeValue('white', 'primaryDark');

  return (
    <Tooltip
      hasArrow
      placement="top"
      fontSize="12px"
      w="180px"
      borderRadius="6px"
      textAlign="center"
      p="10px"
      color={textTooltip}
      bg={bgTooltip}
      label={
        <Box>
          <Text>
            {previewData.scheduled_pass_template_updates.length} scheduled
            update
            {previewData.scheduled_pass_template_updates.length > 1 ? 's' : ''}
          </Text>
        </Box>
      }
    >
      <Box>
        <CustomCalendarIcon boxSize="24px" data-testid="calendar-icon" />
      </Box>
    </Tooltip>
  );
}

export default ScheduledUpdatesThumbnail;
