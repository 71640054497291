import {
  Box,
  HStack,
  VStack,
  Heading,
  Text,
  Button,
  Stack,
  useMediaQuery,
  Skeleton,
  useColorModeValue,
  Flex,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import EmptyDefaultSvgAnimated from '../../../theme/illustrations-animated/EmptyDefaultSvgAnimated';
import EmptyDefaultDarkSvgAnimated from '../../../theme/illustrations-animated/EmptyDefaultDarkSvgAnimated';

const DashboardNoPassTemplates = ({ isLoading }) => {
  const [isSmallerThan770] = useMediaQuery('(max-width: 770px)');
  const modeSkeletonStartColor = useColorModeValue('#d8d8d8', '#29323A');
  const modeSkeletonEndColor = useColorModeValue('#EFEFEF', '#515d66');

  const modeEmptySvgIcon = useColorModeValue(
    <EmptyDefaultSvgAnimated />,
    <EmptyDefaultDarkSvgAnimated />
  );

  return (
    <Box
      w="full"
      bg="linear-gradient(81.62deg, #FF8477 2.25%, #EC6759 79.87%)"
      borderRadius="15px"
      boxShadow="0px 3.5px 5.5px rgba(0, 0, 0, 0.02)"
      p={7}
      color="white"
    >
      <VStack align="start" gap={'18px'} mb={isSmallerThan770 ? 0 : 3}>
        <Heading size="xl">
          <Skeleton
            isLoaded={!isLoading}
            startColor={modeSkeletonStartColor}
            endColor={modeSkeletonEndColor}
          >
            Pass Templates
          </Skeleton>
        </Heading>
        <Stack
          direction={{ base: 'column', md: 'row' }}
          gap={1}
          justify="space-around"
          w="full"
        >
          <HStack h="full" gap={{ base: 7, md: 4 }}>
            {!isSmallerThan770 && (
              <Skeleton
                isLoaded={!isLoading}
                startColor={modeSkeletonStartColor}
                endColor={modeSkeletonEndColor}
                borderRadius="10px"
                marginRight="15px"
              >
                {modeEmptySvgIcon}
              </Skeleton>
            )}
          </HStack>
          <Skeleton
            isLoaded={!isLoading}
            startColor={modeSkeletonStartColor}
            endColor={modeSkeletonEndColor}
            borderRadius="15px"
          >
            <Flex
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              {isSmallerThan770 && (
                <Skeleton
                  isLoaded={!isLoading}
                  startColor={modeSkeletonStartColor}
                  endColor={modeSkeletonEndColor}
                  borderRadius="10px"
                  alignItems={'center'}
                >
                  {modeEmptySvgIcon}
                </Skeleton>
              )}
              <Heading size="lg">
                <Skeleton
                  isLoaded={!isLoading}
                  startColor={modeSkeletonStartColor}
                  endColor={modeSkeletonEndColor}
                  align="center"
                  pt={4}
                >
                  You haven't made a pass template
                </Skeleton>
              </Heading>
              <Text pt={2} pb={5} align={'center'}>
                Before creating passes, you'll need to build a pass template.
              </Text>
              <Button
                as={Link}
                to="/pass-templates/create"
                size="sm"
                variant="white"
                fontSize="14px"
                w={44}
              >
                Create Template
              </Button>
            </Flex>
          </Skeleton>
        </Stack>
      </VStack>
    </Box>
  );
};

export default DashboardNoPassTemplates;
