import {
  IconButton,
  Button,
  Grid,
  Badge,
  Flex,
  Text,
  useDisclosure,
  useColorModeValue,
} from '@chakra-ui/react';
import { CustomChevronLeftIcon } from '../../../theme/icons/CustomChevronLeftIcon';
import InfoDrawer from '../../common/InfoDrawer';
import { Link } from 'react-router-dom';
import MoreInfoLine from './MoreInfoLine';
import MessageStatus from './MessageStatus';
import { CustomCsvDownloadIcon } from '../../../theme/icons/CustomCsvDownloadIcon';

function MessageBatchMoreInfo({ cell, cancel }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const modeIconColor = useColorModeValue('secondaryDark08', '#DDDDDD');
  const modeBoxBg = useColorModeValue('white', 'secondaryDark');
  const modeTextBg = useColorModeValue('white', 'primaryDark06');
  const modeLabelBg = useColorModeValue('white', 'primaryDark03');
  const modeBorderColor = useColorModeValue('primaryBackground', 'primaryDark');

  function handleMoreInfoClick(e) {
    e.stopPropagation();
    onOpen();
  }

  const bgColors = {
    modeBoxBg,
    modeTextBg,
    modeBorderColor,
    modeLabelBg,
  };

  return (
    <>
      <IconButton
        aria-label="More Info"
        onClick={handleMoreInfoClick}
        icon={
          <CustomChevronLeftIcon
            w="20px"
            h="20px"
            color={modeIconColor}
            transform="rotate(180deg)"
          />
        }
        variant="white"
        size="sm"
        w="36px"
        mr={1}
      />
      <InfoDrawer
        isOpen={isOpen}
        onClose={onClose}
        header="Message Batch Overview"
        footer={
          <Flex justifyContent="space-between" alignItems="center" w="full">
            <Link
              color={useColorModeValue('secondaryDark08', '#DDDDDD')}
              fontSize="14px"
              onClick={onClose}
              mr="30px"
            >
              <Text textStyle="headingFamilyMedium">Back to list</Text>
            </Link>
            {cell.row.original.status === 'scheduled' && (
              <Button
                size="sm"
                onClick={() =>
                  cancel(cell.row.original.id, cell.row.original.messageType)
                }
              >
                Cancel
              </Button>
            )}
          </Flex>
        }
        bgColors={bgColors}
      >
        <Grid templateColumns={{ base: '150px 1fr', md: '192px 1fr' }} gap={0}>
          <MoreInfoLine heading="Batch Name" value={cell.row.original.name} />
          <MoreInfoLine heading="Batch ID" value={cell.row.original.id} />
          {cell.row.original.tag && (
            <MoreInfoLine
              heading="Tag"
              value={
                cell.row.original.tag ? (
                  <Badge colorScheme="purple">{cell.row.original.tag}</Badge>
                ) : null
              }
            />
          )}
          <MoreInfoLine
            heading="Message Type"
            value={cell.row.original.messageType}
          />
          <MoreInfoLine
            heading="Status"
            value={<MessageStatus status={cell.row.original.status} />}
          />
          {cell.row.original.messageType !== 'Wallet' &&
            (cell.row.original.status === 'failed' ||
              cell.row.original.status === 'cancellation_failed' ||
              cell.row.original.status === 'partially_canceled') && (
              <MoreInfoLine
                heading="Error Message"
                value={cell.row.original.errorMessage}
              />
            )}
          {cell.row.original.messageType === 'Wallet' &&
            cell.row.original.errorsCsv && (
              <MoreInfoLine
                heading="Error CSV"
                value={
                  <Link to={cell.row.original.errorsCsv}>
                    <CustomCsvDownloadIcon color="brand" boxSize={7} />
                  </Link>
                }
              />
            )}
          {cell.row.original.messageType === 'Wallet' &&
            cell.row.original.deliveryReportCsv && (
              <MoreInfoLine
                heading="Delivery Report CSV"
                value={
                  <Link to={cell.row.original.deliveryReportCsv}>
                    <CustomCsvDownloadIcon color="brand" boxSize={7} />
                  </Link>
                }
              />
            )}
          <MoreInfoLine heading="Content" value={cell.row.original.content} />
          {cell.row.original.messageType === 'mms' && (
            <MoreInfoLine
              heading="Media URL"
              value={cell.row.original.mediaUrl}
            />
          )}
          <MoreInfoLine
            heading="Total Recipients"
            value={cell.row.original.recipients}
          />
          {cell.row.original.errors > 0 && (
            <MoreInfoLine
              heading="Total Errors"
              value={cell.row.original.errors}
            />
          )}
          {cell.row.original.scheduledAt && (
            <MoreInfoLine
              heading="Scheduled At"
              value={cell.row.original.scheduledAt}
            />
          )}
          {cell.row.original.completedAt && (
            <MoreInfoLine
              heading="Completed At"
              value={cell.row.original.completedAt}
            />
          )}
        </Grid>
      </InfoDrawer>
    </>
  );
}

export default MessageBatchMoreInfo;
