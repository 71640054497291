import {
  Center,
  Square,
  VStack,
  Text,
  useColorModeValue,
  HStack,
  Box,
  useOutsideClick,
  useColorMode,
  Skeleton,
} from '@chakra-ui/react';
import { useRef, useState } from 'react';
import { CustomExclamationMarkInverseIcon } from '../../theme/icons/CustomExclamationMarkInverseIcon';
import TooltipClientDashboard from '../client/dashboard/TooltipClientDashboard';

const InfoBox = (props) => {
  const { inverse, icon, text, children, brand } = props;
  const modeBoxBg = useColorModeValue('white', 'secondaryDark');
  const modeBoxBgDark = useColorModeValue('gradientDark', 'gradientBrand');
  const modeSquareBg = useColorModeValue('gradientDark', 'primaryDark');
  const modeSquareBgDark = useColorModeValue('quinaryBackground', 'white');
  const modeTextColor = useColorModeValue('secondaryDark08', 'white');
  const modeSkeletonStartColor = useColorModeValue('#d8d8d8', '#29323A');
  const modeSkeletonEndColor = useColorModeValue('#EFEFEF', '#515d66');
  const { colorMode } = useColorMode();
  const [open, setOpen] = useState(false);
  const tooltipRef = useRef();

  useOutsideClick({
    ref: tooltipRef,
    handler: () => {
      setOpen(false);
    },
  });

  const showTooltipHandler = (e) => {
    e.preventDefault();
    open ? setOpen(false) : setOpen(true);
  };

  return (
    <Center
      bg={inverse && brand ? 'brand' : inverse ? modeBoxBgDark : modeBoxBg}
      minH="196px"
      borderRadius="15px"
      p={2}
      boxShadow="0px 3.5px 5.5px rgba(0, 0, 0, 0.02)"
      position={props.tooltipClientDashboard && 'relative'}
    >
      <VStack spacing="6px">
        <Skeleton
          isLoaded={!props.isLoading}
          borderRadius="15px"
          startColor={modeSkeletonStartColor}
          endColor={modeSkeletonEndColor}
        >
          <Square
            size="64px"
            bg={inverse ? modeSquareBgDark : modeSquareBg}
            borderRadius="15px"
            boxShadow="0px 3.5px 5.5px rgba(0, 0, 0, 0.02)"
            className={inverse ? ' ' : 'multicolorDarkBg'}
          >
            {icon}
          </Square>
        </Skeleton>
        {children}
        <Skeleton
          isLoaded={!props.isLoading}
          startColor={modeSkeletonStartColor}
          endColor={modeSkeletonEndColor}
        >
          <HStack>
            <Text
              textStyle="bodyRoman"
              color={inverse ? 'white' : modeTextColor}
              textAlign="center"
              pt="4px"
            >
              {text}
            </Text>
            {props.tooltipClientDashboard && (
              <TooltipClientDashboard open={open} text={text}>
                <Box onClick={showTooltipHandler} ref={tooltipRef}>
                  <CustomExclamationMarkInverseIcon
                    boxSize="20px"
                    mode={colorMode === 'dark' ? 'dark' : 'light'}
                  />
                </Box>
              </TooltipClientDashboard>
            )}
          </HStack>
        </Skeleton>
      </VStack>
    </Center>
  );
};

export default InfoBox;
