import { useContext, useState } from 'react';
import PassTemplateContext from '../../../store/client/PassTemplateContext';
import moment from 'moment';
import {
  useColorModeValue,
  Box,
  Flex,
  Text,
  Switch,
  HStack,
  Button,
  useToast,
} from '@chakra-ui/react';
import { CustomReactSelect } from '../../common/CustomReactSelect';
import { useNavigate } from 'react-router';
import { HttpContext } from '../../../context/HttpContext';
import CustomToast from '../../../common/CustomToast';
import { CustomBinIcon } from '../../../theme/multicolor-icons/CustomBinIcon';

function ScheduledUpdatesBanner({
  previewScheduledUpdate,
  setPreviewScheduledUpdate,
}) {
  const { authAxios } = useContext(HttpContext);
  const navigate = useNavigate();

  const modeLabelBg = useColorModeValue('#EFEFEF', 'primaryDark03');

  const passTemplateCtx = useContext(PassTemplateContext);
  const [originalPassTemplate] = useState(passTemplateCtx);

  const [selectedScheduledUpdate, setSelectedScheduledUpdate] = useState(null);

  const formatScheduledDate = (date) => {
    if (!date) return '';
    return moment(date).format('MMM D, YYYY HH:mm');
  };

  const handlePreviewChange = (e) => {
    const isChecked = e.target.checked;
    setPreviewScheduledUpdate(isChecked);

    if (isChecked) {
      const scheduledUpdateParams = selectedScheduledUpdate.params;
      const scheduledPassTemplateUpdatePreview = {
        ...originalPassTemplate,
        ...scheduledUpdateParams,
      };

      passTemplateCtx.updateAllData(scheduledPassTemplateUpdatePreview);
    } else {
      passTemplateCtx.updateAllData(originalPassTemplate);
    }
  };

  const handleScheduledUpdateChange = (option) => {
    setSelectedScheduledUpdate(option.value);
    handlePreviewChange({ target: { checked: previewScheduledUpdate } });
  };

  const toast = useToast();

  const handleCancelScheduledUpdate = async () => {
    let loadingToast;
    try {
      const response = await authAxios.delete(
        `/passes/templates/scheduled_pass_template_updates/${selectedScheduledUpdate.id}`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.status !== 200) {
        throw new Error('Failed to cancel scheduled update');
      }

      toast({
        render: ({ onClose }) => (
          <CustomToast
            status="success"
            title="Scheduled update cancelled"
            description="Scheduled update has been cancelled"
            onClose={onClose}
          />
        ),
      });

      setTimeout(() => {
        window.location.reload(true);
      }, 1000);
    } catch (error) {
      toast({
        render: ({ onClose }) => (
          <CustomToast
            status="error"
            title="Error"
            description="Failed to cancel scheduled update"
            onClose={onClose}
          />
        ),
      });
    }
  };

  return (
    passTemplateCtx.scheduledPassTemplateUpdates.length > 0 && (
      <Box
        position="relative"
        mb={6}
        bg={modeLabelBg}
        className="autofillForDarkBg"
        borderRadius="15px"
        p="15px"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Flex
          direction="column"
          justifyContent="center"
          alignItems="center"
          gap={2}
        >
          <HStack>
            <Text>This template will be updated on: </Text>
            <Box my={5} mx={1} zIndex={9999}>
              <CustomReactSelect
                options={passTemplateCtx.scheduledPassTemplateUpdates.map(
                  (update) => ({
                    label: formatScheduledDate(update.scheduled_at),
                    value: update,
                  })
                )}
                formatOptionLabel={(option) =>
                  formatScheduledDate(option.value.scheduled_at)
                }
                onChange={handleScheduledUpdateChange}
                value={
                  selectedScheduledUpdate
                    ? {
                        label: formatScheduledDate(
                          selectedScheduledUpdate.scheduled_at
                        ),
                        value: selectedScheduledUpdate,
                      }
                    : null
                }
                placeholder="Select Scheduled Update"
                id="passTemplate"
                PassTemplate
              />
            </Box>
          </HStack>
          <HStack>
            <Switch
              isChecked={previewScheduledUpdate}
              onChange={handlePreviewChange}
              isDisabled={!selectedScheduledUpdate}
              opacity={!selectedScheduledUpdate ? 0.4 : 1}
            >
              Preview Changes
            </Switch>
          </HStack>
        </Flex>

        <Button
          position="absolute"
          top="15px"
          right="0px"
          variant="secondary"
          onClick={handleCancelScheduledUpdate}
          isDisabled={!selectedScheduledUpdate}
          alt="Cancel Update"
          size="sm"
          mr="24px"
        >
          <Flex alignItems="center" gap={2}>
            <CustomBinIcon />
            Cancel Update
          </Flex>
        </Button>
      </Box>
    )
  );
}

export default ScheduledUpdatesBanner;
