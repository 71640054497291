import appsignal from '../appsignal';
import React, { createContext, useCallback, useState } from 'react';
// import axios from 'axios';
import { useColorMode } from '@chakra-ui/react';

const AuthContext = createContext();
const { Provider } = AuthContext;

function parseJwt(jwt) {
  const base64Url = jwt.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
}

// const publicAxios = axios.create({
//   baseURL: env.REACT_APP_API_URL_AXIOS,
// });

export const KEYS = {
  // token: process.env.REACT_APP_TOKEN,
  // exp: process.env.REACT_APP_EXP,
  // user: process.env.REACT_APP_USER,
  // refreshToken: process.env.REACT_APP_REFRESH_TOKEN,
  // refreshTokenTTL: process.env.REACT_APP_REFRESH_TOKEN_TTL_SEC,

  exp: 'passkings-exp',
  refreshToken: 'passkings-refresh-token',
  refreshTokenTTL: 'passkings-refresh-token-ttl-sec',
  token: 'passkings-token',
  account: 'passkings-account',
};

function getRefreshTokenExpInSeconds() {
  try {
    return (
      Math.floor(new Date().getTime() / 1000) +
      parseInt(process.env.REACT_APP_REFRESH_TOKEN_TTL_SEC, 10)
    );
  } catch (onError) {
    appsignal.sendError(onError);
    console.log(onError);
    return 0;
  }
}

function getExpiresAt(token) {
  const payload = parseJwt(token);
  let { exp } = payload;
  const refreshTokenExp = getRefreshTokenExpInSeconds();
  if (exp < refreshTokenExp) {
    exp = refreshTokenExp;
  }

  return exp;
}

const AuthProvider = ({ children }) => {
  const token = localStorage.getItem(KEYS.token);
  const exp = localStorage.getItem(KEYS.exp);
  const account = localStorage.getItem(KEYS.account);
  const refreshToken = localStorage.getItem(KEYS.refreshToken);

  const { colorMode, toggleColorMode } = useColorMode();

  const [authState, setAuthState] = useState({
    token,
    exp,
    account: account ? JSON.parse(account) : {},
    refreshToken,
  });

  const setAuthInfo = ({ token, account, refreshToken }) => {
    const exp = getExpiresAt(token);
    localStorage.setItem(KEYS.token, token);
    localStorage.setItem(KEYS.account, JSON.stringify(account));
    localStorage.setItem(KEYS.exp, exp);
    localStorage.setItem(KEYS.refreshToken, refreshToken);

    setAuthState({ token, account, exp, refreshToken });
  };

  const setAccount = (account) => {
    localStorage.setItem(KEYS.account, JSON.stringify(account));
    setAuthState({
      ...authState,
      account,
    });
  };

  const setTokens = ({ token, refreshToken }) => {
    const exp = getExpiresAt(token);
    localStorage.setItem(KEYS.token, token);
    localStorage.setItem(KEYS.exp, exp);
    localStorage.setItem(KEYS.refreshToken, refreshToken);

    // ...authState
    setAuthState({ token, exp, refreshToken });
  };

  const logout = useCallback(async () => {
    if (colorMode === 'dark') {
      toggleColorMode();
    }
    try {
      localStorage.removeItem(KEYS.token);
      localStorage.removeItem(KEYS.exp);
      localStorage.removeItem(KEYS.account);
      localStorage.removeItem(KEYS.refreshToken);
      setAuthState({
        token: null,
        exp: null,
        account: {},
        refreshToken: null,
      });
    } catch (onError) {
      appsignal.sendError(onError);
      console.log(onError);
    }
  }, [token, setAuthState]);

  const isAuthenticated = () => {
    if (!authState.exp) {
      return false;
    }
    // return new Date() < new Date(authState.exp * 1000);
    return true;
  };

  const isAdmin = () => {
    const roles = authState?.account?.roles || [];
    return roles.includes('ROLE_ADMIN') && isAuthenticated();
  };

  const getAccessToken = () => {
    return localStorage.getItem(KEYS.token);
  };

  const getRefreshToken = () => {
    return localStorage.getItem(KEYS.refreshToken);
  };

  // const getNewToken = async () => {
  //   try {
  //     const { token, refresh_token } = await publicAxios.post('/token/refresh', {
  //       refresh_token: getRefreshToken(),
  //     });
  //     setTokens({ token, refreshToken: refresh_token });
  //   } catch (onError) {
  //     appsignal.sendError(onError);
  //     console.log(onError);
  //     return onError;
  //   }
  // };

  // const getNewTokenForRequest = async (failedRequest) => {
  //   console.log('auth post', failedRequest);
  //   try {
  //     const { data } = await publicAxios.post('/token/refresh', {
  //       refresh_token: getRefreshToken(),
  //     });

  //     const { token, refresh_token } = data;

  //     const payload = parseJwt(token);
  //     const { exp } = payload;
  //     localStorage.setItem(KEYS.token, token);
  //     localStorage.setItem(KEYS.refreshToken, refresh_token);
  //     localStorage.setItem(KEYS.exp, exp);

  // failedRequest.response.config.headers['Authorization'] = `Bearer ${data.token}`;
  // failedRequest.response.config.headers.common[`X-Auth-Token`] = token;
  //   failedRequest.response.config.headers[`X-Auth-Token`] = token;

  //   setTokens({ token, refreshToken: refresh_token });

  //   return Promise.resolve();
  // } catch (onError) {
  //   appsignal.sendError(onError);
  //   console.log('failed', onError);
  //   await logout();
  // const message = getMessage(onError);
  // if (message) {
  //   toast({
  //     position: 'top-left',
  //     description: message,
  //     status: 'error',
  //     duration: 6000,
  //     isClosable: true,
  //   });
  //   logout();
  // }
  //   }
  // };

  return (
    <Provider
      value={{
        KEYS,
        authState,
        setAuthState: (authInfo) => setAuthInfo(authInfo),
        setAuthTokens: (tokens) => setTokens(tokens),
        setAuthAccount: (account) => setAccount(account),
        logout,
        parseJwt,
        isAuthenticated,
        isAdmin,
        getAccessToken,
        getRefreshToken,
        // getNewToken,
        // getNewTokenForRequest,
      }}
    >
      {children}
    </Provider>
  );
};

export { AuthContext, AuthProvider };
