import appsignal from '../../../appsignal';
import { useCallback, useMemo, useContext, useRef, useEffect } from 'react';
import ApiKeysListTable from './ApiKeysListTable';
import KeysListContext from '../../../store/client/KeysListContext';
import CustomToast from '../../../common/CustomToast';
import { useToast } from '@chakra-ui/react';
import { getErrorResponsePayload } from '../../../utils/ajax';
import { HttpContext } from '../../../context/HttpContext';
import { MeContext } from '../../../context/MeContext';

const ApiKeysList = () => {
  const { authAxios } = useContext(HttpContext);
  const keysListCtx = useContext(KeysListContext);
  const meCtx = useContext(MeContext);
  const { filterEntity } = meCtx?.state;
  const toast = useToast();
  const fetchIdRef = useRef(0);

  const { items, total, pageCount, loading } = keysListCtx.keyState;

  const columns = useMemo(
    () => [
      {
        Header: 'API KEY NAME',
        accessor: 'apiKeyName',
        className: 'modeTdColor',
        style: {
          fontFamily: 'Inter Bold, sans-serif',
          fontSize: '16px',
          wordBreak: 'break-word',
          minWidth: '100px',
        },
      },
      {
        Header: 'API KEY',
        accessor: 'apiKey',
        style: {
          minWidth: '110px',
        },
      },
      // {
      //   Header: 'LAST USED',
      //   accessor: 'lastTimeUsed',
      //   style: {
      //     minWidth: '110px',
      //   },
      // },
      {
        Header: '',
        accessor: 'visibility',
      },
      {
        Header: '',
        accessor: 'action',
      },
      {
        Header: ' ',
        accessor: 'moreInfo',
        style: {
          width: '24px',
          padding: 0,
        },
      },
    ],
    []
  );

  const fetchData = useCallback(() => {
    // Give this fetch an ID
    const fetchId = ++fetchIdRef.current;
    keysListCtx.updateKeyState({
      ...keysListCtx.keyState,
      loading: true,
      status: 'pending',
    });

    setTimeout(async () => {
      if (fetchId === fetchIdRef.current) {
        try {
          const url = filterEntity
            ? `keys?entity_id=${filterEntity?.id}`
            : 'keys';
          const { data } = await authAxios.get(url);
          const keys = data?.data || [];
          const total = data?.data.length ?? 0;
          keysListCtx.updateKeyState({
            ...keysListCtx.keyState,
            items: keys,
            total: total,
            loading: false,
            status: 'resolved',
          });
        } catch (error) {
          appsignal.sendError(error);
          console.log(error);
          keysListCtx.updateKeyState({
            ...keysListCtx.keyState,
            items: [],
            total: 0,

            loading: false,
            error: 'Something went wrong',
            status: 'rejected',
          });
          const { message, code } = getErrorResponsePayload(error);
          code !== 401 &&
            toast({
              render: (props) => (
                <CustomToast
                  status="error"
                  title={message}
                  // description="Please try again later."
                  onClose={props.onClose}
                />
              ),
            });
        }
      }
    }, 0);
  }, [authAxios, filterEntity]);

  const normalizedData = (data) => {
    if (data?.length > 0) {
      return data.map((item) => {
        return {
          ...item,
          apiKeyName: item.name,
          apiKey: item.token,
          lastTimeUsed: item.lastTimeUsed,
          exampleForNestedObject: {
            keySinglePageUrl: '/developer-tools/' + item.uuid,
          },
        };
      });
    }
    return [];
  };

  const formattedData = useMemo(() => normalizedData(items), [items]);
  return (
    <ApiKeysListTable
      columns={columns}
      data={formattedData}
      fetchData={fetchData}
      isLoading={loading}
      pageCount={pageCount}
      total={total}
    />
  );
};
export default ApiKeysList;
