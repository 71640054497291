import {
  useMemo,
  useContext,
  useRef,
  useCallback,
  useState,
  useEffect,
} from 'react';
import BatchListContext from '../../../store/client/BatchListContext';
import PassesBatchesTable from './PassesBatchesTable';
import { HttpContext } from '../../../context/HttpContext';
import moment from 'moment';

const PassesBatchesList = ({ filterEntity }) => {
  const { authAxios } = useContext(HttpContext);
  const { dataState, updateDataState } = useContext(BatchListContext);
  const fetchIdRef = useRef(0);

  const columns = useMemo(() => {
    return [
      {
        Header: 'Name',
        accessor: 'name',
        style: {
          fontFamily: 'Inter Bold, sans-serif',

          minWidth: '150px',
          width: '250px',
        },
      },
      {
        Header: 'Pass Template',
        accessor: 'passTemplate',
        style: {
          minWidth: '200px',
          width: '350px',
          textAlign: 'center',
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
        style: {
          minWidth: '90px',
          width: '200px',
        },
      },
      {
        Header: 'Progress',
        accessor: 'progress',
        style: {
          minWidth: '90px',
          width: '200px',
        },
      },
      {
        Header: 'Total Passes',
        accessor: 'totalPasses',
        style: {
          minWidth: '150px',
          width: '200px',
        },
      },
      {
        Header: 'Completed At',
        accessor: 'completedAt',
        style: {
          minWidth: '150px',
          width: '200px',
        },
      },
      {
        Header: 'CSV Results',
        accessor: 'csvResults',
        style: {
          minWidth: '70px',
          width: '200px',
          textAlign: 'center',
        },
      },
    ];
  }, []);

  const fetchData = useCallback(
    ({ pageSize, pageIndex }) => {
      const fetchId = ++fetchIdRef.current;

      updateDataState({
        loading: true,
        status: 'pending',
      });

      setTimeout(async () => {
        if (fetchId === fetchIdRef.current) {
          try {
            const url = filterEntity
              ? `/api/v1/entities/${filterEntity?.uuid}/batches`
              : '/api/v1/batches';
            const response = await authAxios.get(
              `${url}?per_page=${pageSize}&page=${pageIndex + 1}&type[]=passCreation`
            );
            const batches = response?.data?.data || [];
            const total = response?.data?.meta?.totalCount ?? 0;

            updateDataState({
              items: batches,
              total,
              pageCount: Math.ceil(total / pageSize),
              loading: false,
              status: 'resolved',
            });
          } catch (error) {
            updateDataState({
              items: [],
              total: 0,
              pageCount: 0,
              loading: false,
              status: 'rejected',
            });
          }
        }
      }, 0);
    },
    [authAxios, filterEntity]
  );

  useEffect(() => {
    fetchData({ pageSize: 10, pageIndex: 0 });
  }, [fetchData, filterEntity]);

  const normalizedData = useMemo(() => {
    if (dataState.items.length > 0) {
      return dataState.items.map((item) => ({
        id: item.id,
        name: item.attributes.name,
        passTemplate: item.attributes.passTemplateName,
        status: item.attributes.status,
        progress:
          item.attributes.progress?.processed !== undefined &&
          item.attributes.progress?.total
            ? (item.attributes.progress.processed /
                item.attributes.progress.total) *
              100
            : null,
        totalPasses: item.attributes.passCount,
        completedAt: item.attributes.completedAt
          ? moment(item.attributes.completedAt).format('MMM D, YYYY HH:mm')
          : null,
        csvResults: item.attributes.resultCsvUrl,
      }));
    }
    return [];
  }, [dataState.items]);

  return (
    <>
      <PassesBatchesTable
        columns={columns}
        data={normalizedData}
        fetchData={fetchData}
        isLoading={dataState.loading}
        pageCount={dataState.pageCount}
        total={dataState.total}
        filterEntity={filterEntity}
      />
    </>
  );
};

export default PassesBatchesList;
