import {
  Button,
  Divider,
  Flex,
  Heading,
  Skeleton,
  Square,
  Text,
  useColorModeValue,
  useMediaQuery,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';

import { ClientDashboardStatsContext } from '../../../store/client/ClientDashboardStatsContext';
import { useContext } from 'react';
import { CustomTapSuccessIcon } from '../../../theme/icons/CustomTapSuccessIcon';
import { CustomRejectedTapIcon } from '../../../theme/icons/CustomRejectedTapIcon';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { format, utcToZonedTime } from 'date-fns-tz';

const DashboardPassTaps = (props) => {
  const { dashboardState } = useContext(ClientDashboardStatsContext);
  const { totalPassTaps, successfulPassTaps, lastPassTap } = dashboardState;
  const { isLoading } = props;
  const modeSkeletonStartColor = useColorModeValue('#d8d8d8', '#29323A');
  const modeSkeletonEndColor = useColorModeValue('#EFEFEF', '#515d66');
  const modeBoxBg = useColorModeValue('white', 'secondaryDark');
  // const modeBoxBgDark = useColorModeValue('gradientDark', 'gradientBrand');
  // const modeSquareBg = useColorModeValue('gradientDark', 'primaryDark');
  // const modeSquareBgDark = useColorModeValue('quinaryBackground', 'white');
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const [isSmallerThan600px] = useMediaQuery('(max-width: 600px)');

  const lastPassTapStatus = lastPassTap
    ? lastPassTap?.successful
      ? 'Accepted'
      : 'Rejected'
    : 'No data yet';

  const getDisplayTime = (isoTimeString) => {
    const zonedDate = utcToZonedTime(isoTimeString, timeZone);
    const formattedDate = format(zonedDate, 'dd/MM/yyyy hh:mm:ss a', timeZone);
    return formattedDate;
  };

  const lastPassTapTime = lastPassTap
    ? getDisplayTime(lastPassTap?.created_at)
    : 'No data yet';

  return (
    <Flex
      direction="column"
      gap={{ base: 5, md: 10 }}
      background="secondaryDark"
      borderRadius="15px"
      boxShadow="0px 3.5px 5.5px rgba(0, 0, 0, 0.02)"
      w="full"
      p={isSmallerThan600px ? 10 : 12}
      color="white"
      {...props}
    >
      <Heading size="xl">
        <Skeleton
          isLoaded={!isLoading}
          startColor={modeSkeletonStartColor}
          endColor={modeSkeletonEndColor}
        >
          Pass taps
        </Skeleton>
      </Heading>
      <Flex
        justifyContent="space-between"
        gap={{ base: 7, md: 14 }}
        direction={{ base: 'column', sm: 'row' }}
      >
        <Flex
          direction="row"
          justifyContent="space-between"
          width="100%"
          gap={8}
        >
          {!isSmallerThan600px && (
            <Skeleton
              isLoaded={!isLoading}
              startColor={modeSkeletonStartColor}
              endColor={modeSkeletonEndColor}
              borderRadius="10px"
            >
              <div style={{ width: '150px' }}>
                <CircularProgressbarWithChildren
                  maxValue={totalPassTaps}
                  value={successfulPassTaps}
                  circleRatio={1}
                  strokeWidth={16}
                  styles={{
                    trail: {
                      strokeLinecap: 'butt',
                      transformOrigin: 'center center',
                      stroke: `white`,
                    },
                    path: {
                      // transform: 'rotate(0.50turn)',
                      strokeLinecap: 'butt',
                      transformOrigin: 'center center',
                      stroke: `#FF8377`,
                    },
                    text: {
                      fill: `white`,
                      fontWeight: 'bold',
                    },
                  }}
                >
                  <Text
                    letterSpacing={1}
                    fontSize="16px"
                    fontWeight="extrabold"
                  >
                    {totalPassTaps}
                  </Text>
                  <Text>Total</Text>
                </CircularProgressbarWithChildren>
              </div>
            </Skeleton>
          )}
          <Skeleton
            isLoaded={!isLoading}
            borderRadius="15px"
            startColor={modeSkeletonStartColor}
            endColor={modeSkeletonEndColor}
          >
            <Flex direction="column" gap={3} pt={3}>
              <Flex direction="row" alignItems="center" gap={2}>
                <Square
                  size="36px"
                  bg="#FF8377"
                  borderRadius="8px"
                  boxShadow="0px 3.5px 5.5px rgba(0, 0, 0, 0.02)"
                  // className={inverse ? ' ' : 'multicolorDarkBg'}
                >
                  <CustomTapSuccessIcon />
                </Square>
                <Text>Accepted</Text>
              </Flex>
              <Heading size="xl">{successfulPassTaps}</Heading>
              <Divider
                borderColor="#FF8377"
                borderRadius="full"
                borderWidth="2px"
                w="100px"
              />
            </Flex>
          </Skeleton>
          <Skeleton
            isLoaded={!isLoading}
            borderRadius="15px"
            startColor={modeSkeletonStartColor}
            endColor={modeSkeletonEndColor}
          >
            <Flex direction="column" gap={3} pt={3}>
              <Flex direction="row" alignItems="center" gap={2}>
                <Square
                  size="36px"
                  bg={modeBoxBg}
                  borderRadius="8px"
                  boxShadow="0px 3.5px 5.5px rgba(0, 0, 0, 0.02)"
                  // className={inverse ? ' ' : 'multicolorDarkBg'}
                >
                  <CustomRejectedTapIcon />
                </Square>
                <Text>Rejected</Text>
              </Flex>
              <Heading size="xl">{totalPassTaps - successfulPassTaps}</Heading>
              <Divider borderRadius="full" borderWidth="2px" w="100px" />
            </Flex>
          </Skeleton>
        </Flex>
      </Flex>
      <Skeleton
        isLoaded={!isLoading}
        startColor={modeSkeletonStartColor}
        endColor={modeSkeletonEndColor}
        borderRadius="10px"
      >
        <Flex
          direction={isSmallerThan600px ? 'column' : 'row'}
          gap={{ base: 3 }}
          justifyContent={!isSmallerThan600px && 'space-between'}
          align={{ base: 'center' }}
          w="full"
          mt={{ base: 3, md: 0 }}
        >
          <Flex direction="column">
            <Text>Last tap: {lastPassTapTime}</Text>
            <Text>STATUS: {lastPassTapStatus}</Text>
          </Flex>
          <Button
            as={Link}
            to="/live-data"
            size="sm"
            variant="white"
            fontSize="14px"
            w={44}
            mr={{ base: 0, md: 3 }}
            mt={{ base: 2, md: 0 }}
          >
            All Pass Taps
          </Button>
        </Flex>
      </Skeleton>
    </Flex>
  );
};

export default DashboardPassTaps;
