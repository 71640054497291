import {
  Container,
  Divider,
  Button,
  Grid,
  GridItem,
  Spinner,
  Box,
  useColorModeValue,
} from '@chakra-ui/react';
import Breadcrumbs from '../../common/Breadcrumbs';
import EmptyState from '../../common/EmptyState';
import { Link as RouterLink } from 'react-router-dom';
import PassTemplatesListItem from '../../components/client/pass-templates/PassTemplatesListItem';
import EmptyDefaultSvgAnimated from '../../theme/illustrations-animated//EmptyDefaultSvgAnimated';
import EmptyDefaultDarkSvgAnimated from '../../theme/illustrations-animated//EmptyDefaultDarkSvgAnimated';
import PassTemplateHeader from '../../components/client/pass-templates/PassTemplateHeader';
import useOrganizationStatus from '../../hooks/useOrganisationStatus';

function PassTemplatesPage({
  items,
  isLoading,
  hasMore,
  loadMore,
  filtersApplied,
  setInputSearch,
}) {
  const modeEmptySvgIcon = useColorModeValue(
    <EmptyDefaultSvgAnimated />,
    <EmptyDefaultDarkSvgAnimated />
  );
  const { shouldRender, components } = useOrganizationStatus(modeEmptySvgIcon);

  const breadcrumbLinks = [
    {
      linkName: 'Home',
      linkUrl: '/',
    },
    {
      linkName: 'Pass templates',
      linkUrl: null,
    },
  ];

  let totalNumberOfTemplatePreviews = items.length;

  return (
    <Container display="flex" flexDir="column" flex="1" pb={6}>
      <Breadcrumbs breadcrumbLinks={breadcrumbLinks} />
      <PassTemplateHeader setInputSearch={setInputSearch} />
      <Divider borderColor="primaryBackground" mb={6} />

      {components}
      {totalNumberOfTemplatePreviews > 0 && (
        <>
          <Grid
            //templateColumns="repeat(auto-fill, 343px)"
            templateColumns={{
              base: 'repeat(auto-fill, 343px)',
              xl: 'repeat(auto-fill, 330px)',
              '2xl': 'repeat(auto-fill, 343px)',
            }}
            justifyContent={{
              base: 'center',
              xl: totalNumberOfTemplatePreviews > 2 ? 'center' : 'flex-start',
            }}
            //gridColumnGap="64px"
            gridColumnGap={{
              base: '64px',
              xl: '20px',
              '2xl': '64px',
            }}
            gridRowGap="40px"
          >
            {items.map((previewData, index) => {
              return (
                <GridItem key={index} borderRadius="10px">
                  <PassTemplatesListItem previewData={previewData} />
                </GridItem>
              );
            })}
          </Grid>
          {!isLoading && hasMore && (
            <Box mt="30px" textAlign="center">
              <Button
                alt="Load more templates"
                size="sm"
                onClick={(e) => {
                  e.preventDefault();
                  loadMore();
                }}
              >
                Load more templates
              </Button>
            </Box>
          )}
        </>
      )}
      {isLoading && shouldRender && (
        <>
          <Spinner
            width="52px"
            height="52px"
            thickness="4px"
            speed="0.65s"
            emptyColor="quinaryBackground"
            mt={4}
            mx="auto"
          />
        </>
      )}
      {shouldRender &&
        !isLoading &&
        totalNumberOfTemplatePreviews === 0 &&
        !filtersApplied && (
          <EmptyState
            iconSvgAnimated={modeEmptySvgIcon}
            heading="There are no Pass templates yet."
            text="Click on the button below and start creating&nbsp;NFC&nbsp;pass&nbsp;templates."
          >
            <Button
              alt="Create pass template"
              size="sm"
              as={RouterLink}
              to="/pass-templates/create"
            >
              Create pass template
            </Button>
          </EmptyState>
        )}
      {shouldRender &&
        !isLoading &&
        totalNumberOfTemplatePreviews === 0 &&
        filtersApplied && (
          <EmptyState
            iconSvgAnimated={modeEmptySvgIcon}
            heading="No results found."
            text="Try adjusting your filters to find the pass templates that you are looking for."
          />
        )}
    </Container>
  );
}

export default PassTemplatesPage;
